import React from 'react';

import {
  Row,
  Col,
  List,
  IconButton,
  Dropdown,
  Loader,
  SelectPicker,
  Stack,
} from 'rsuite';
import {
  PhoneMissed,
  PhoneOutgoing,
  PhoneIncoming,
  PhoneCall,
  RefreshCw,
} from 'react-feather';
import { colors } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSelectedLog, getCallLogs, filterCallLogs } from './homeSlice';
import { makeCall } from '../OngoingCall/callSlice';

const generateDirectionIcon = (direction) => {
  switch (direction) {
    case 'incoming':
      return <PhoneIncoming color={colors.secondary} size={18} />;

    case 'outbound':
      return <PhoneOutgoing color={colors.secondary} size={18} />;

    case 'missed':
      return <PhoneMissed color='red' size={18} />;

    default:
      return '';
  }
};

const LeftSidebar = () => {
  const appState = useSelector((state) => state.app);
  const state = useSelector((state) => state.home);

  const dispatch = useDispatch();
  return (
    <>
      <h3
        style={{
          maxWidth: '7.5rem',
          fontWeight: 'lighter',
          marginBottom: '1rem',
          borderBottom: `3px solid ${colors.primary}`,
        }}
      >
        Call Logs
      </h3>
      {state.callLogLoading ? (
        <Loader backdrop content='loading...' vertical />
      ) : (
        <>
          <Stack
            justifyContent='space-around'
            alignItems='center'
            style={{ marginBottom: '1rem' }}
          >
            <SelectPicker
              value={state.filterDirection}
              onChange={(value) => dispatch(filterCallLogs(value))}
              cleanable={false}
              data={[
                {
                  label: 'All',
                  value: 'all',
                },
                {
                  label: 'Outgoing',
                  value: 'outbound',
                },
                {
                  label: 'Incoming',
                  value: 'incoming',
                },
                {
                  label: 'Missed',
                  value: 'missed',
                },
              ]}
              searchable={false}
              style={{ width: '12rem' }}
            />
            <IconButton
              icon={<RefreshCw size={20} />}
              circle
              size='xs'
              onClick={() => dispatch(getCallLogs())}
            />
          </Stack>

          <List
            size='sm'
            style={{
              overflowY: 'scroll',
              minHeight: '72vh',
              maxHeight: '72vh',
            }}
            hover
          >
            {state.callLogs.map((item, index) => (
              <List.Item
                style={{
                  cursor: 'pointer',
                  background:
                    state.selectedIndex === index ? colors.background : '',
                }}
                onClick={() => dispatch(fetchSelectedLog(index))}
                key={index}
                index={index}
              >
                <Row>
                  <Col sm={4}>
                    <span>{generateDirectionIcon(item.direction)}</span>
                  </Col>
                  <Col sm={14}>
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        color: colors.primary,
                      }}
                    >
                      {item.destination_number}
                    </span>
                    <br />
                    <span
                      style={{ color: colors.secondary, fontSize: '0.70rem' }}
                    >
                      {new Date(item.start_time).toLocaleString()}
                    </span>
                  </Col>
                  <Col sm={6}>
                    <Dropdown
                      noCaret
                      placement='bottomEnd'
                      menuStyle={{ maxHeight: '15rem', overflowY: 'scroll' }}
                      renderToggle={(props, ref) => (
                        <IconButton
                          {...props}
                          ref={ref}
                          icon={<PhoneCall size={18} />}
                          circle
                        />
                      )}
                    >
                      {appState.phoneNumbers.map((availableNumber, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() =>
                            dispatch(
                              makeCall({
                                number: item.destination_number,
                                outgoingNumber: availableNumber.number,
                              })
                            )
                          }
                        >
                          {availableNumber.number}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  </Col>
                </Row>
              </List.Item>
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default LeftSidebar;
