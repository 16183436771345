import React, { useState } from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  List,
  IconButton,
  InputGroup,
  Input,
  Stack,
  Loader,
} from 'rsuite';
import { PhoneCall } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import { colors, filterNumber, verifyPhoneNumber } from '../utils';
import { addUser, getUsers, updateCallState } from './callSlice';

const AddParticipantModal = ({ modelState, toggleModal }) => {
  const state = useSelector((state) => state.call);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const dispatch = useDispatch();

  const filterSearchResult = (value) => {
    setPhoneNumber(value);
    setIsNumberValid(verifyPhoneNumber(value));

    const newUsers = state.users.filter((participant) =>
      participant.label.toLowerCase().includes(value.toLowerCase())
    );
    dispatch(updateCallState({ key: 'users', value: newUsers }));
  };

  return (
    <>
      <Modal
        open={modelState}
        onClose={toggleModal}
        size='xs'
        onEnter={() => dispatch(getUsers())}
      >
        <Modal.Header>
          <Modal.Title>Add Participant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            state.addParticipantLoading ? (
              <Loader backdrop content='loading...' vertical />
            ) : (
              // ) : state.users.length ? (
              <List
                size='sm'
                style={{
                  overflowY: 'scroll',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
                hover
              >
                <Stack
                  alignItems='center'
                  justifyContent='space-between'
                  style={{ width: '90%', marginBottom: '0.7rem' }}
                >
                  <InputGroup inside style={{ width: '20rem' }}>
                    <Input
                      value={phoneNumber}
                      onChange={(value) => filterSearchResult(value)}
                      placeholder='Search name or enter a phone number'
                    />
                    <InputGroup.Button disabled={!isNumberValid}>
                      <PhoneCall
                        onClick={() =>
                          dispatch(
                            addUser({
                              toggleModal,
                              sipEndpoint: filterNumber(phoneNumber),
                            })
                          )
                        }
                      />
                    </InputGroup.Button>
                  </InputGroup>
                </Stack>
                {state.users.map((item, index) => (
                  <List.Item
                    style={{
                      width: '20rem',
                    }}
                    key={index}
                    index={index}
                  >
                    <Row>
                      <Col sm={18}>
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            color: colors.primary,
                          }}
                        >
                          {item.label}
                        </span>
                        <br />
                        <span
                          style={{
                            color: item.state ? 'red' : 'green',
                          }}
                        >
                          {item.state ? 'Busy' : 'Available'}
                        </span>
                      </Col>
                      <Col sm={6} smPush={3}>
                        <IconButton
                          disabled={item.state}
                          icon={
                            <PhoneCall
                              color={item.state ? colors.secondary : 'green'}
                              size={18}
                            />
                          }
                          circle
                          onClick={() =>
                            dispatch(
                              addUser({ toggleModal, sipEndpoint: item.value })
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </List.Item>
                ))}
              </List>
            )
            // ) : (
            //   <p style={{ textAlign: 'center' }}>No User Found</p>
          }
        </Modal.Body>
        <Modal.Footer>
          <p>Note: Add button will add another caller to the same call.</p>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddParticipantModal;
