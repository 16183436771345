import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import { SERVER_URL } from '../utils';
import { sendMessage } from '../App/appSlice';

const initialState = {
  backupPhoneStatus: false,
  backupOutboundStatus: false,
  backupPhoneCountryCode: '+1',
  backupPhone: '',
  voicemailStatus: false,
  voicemailRecordingType: 'text',
  voicemailTextRecording: '',
  voicemailFileRecording: '',
  backupPhoneLoading: false,
  voicemailLoading: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserState: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    updateUserData: (state, action) => {
      state.backupPhoneStatus = action.payload.backupPhoneStatus || false;
      state.backupOutboundStatus = action.payload.backupOutboundStatus || false;
      state.backupPhoneCountryCode =
        action.payload.backupPhoneCountryCode || '+1';
      state.backupPhone = action.payload.backupPhone || '';
      state.voicemailStatus = action.payload.voicemailStatus || false;
      state.voicemailRecordingType =
        action.payload.voicemailRecordingType || 'text';
      state.voicemailTextRecording =
        action.payload.voicemailTextRecording || '';
      state.voicemailFileRecording =
        action.payload.voicemailFileRecording || '';
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUserState, updateUserData } = userSlice.actions;

export const getSipUserData = (action) => async (dispatch, getState) => {
  try {
    dispatch(updateUserState({ key: action, value: true }));
    const userData = await axios.post(`${SERVER_URL}/user/getSipDetails`);
    const sipUserData = userData.data.data;
    dispatch(
      updateUserData({
        ...sipUserData,
      })
    );
    dispatch(updateUserState({ key: 'backupPhoneLoading' }));
    dispatch(updateUserState({ key: action, value: false }));
  } catch (err) {
    dispatch(updateUserState({ key: action, value: false }));
    dispatch(
      sendMessage({
        type: 'error',
        text:
          err?.response?.data?.message ||
          'Something Went Wrong. Please try again',
      })
    );
  }
};

export const savePhoneData = (action) => async (dispatch, getState) => {
  try {
    const appState = getState().app;
    const state = getState().user;
    const closeBackupModal = action;
    if (!state.backupPhone || !state.backupPhoneCountryCode) {
      dispatch(
        sendMessage({
          type: 'error',
          text: 'Please fill the details before saving!',
        })
      );
      return;
    }
    const data = {
      backupType: 'phone',
      backupPhone: state.backupPhone,
      backupPhoneStatus: state.backupPhoneStatus,
      backupOutboundStatus: state.backupOutboundStatus,
      backupPhoneCountryCode: state.backupPhoneCountryCode,
    };
    dispatch(updateUserState({ key: 'backupPhoneLoading', value: true }));
    await axios.post(`${SERVER_URL}/user/updateBackupNumber`, data);
    dispatch(
      sendMessage({
        type: 'success',
        text: 'Backup Phone updated successfully.',
      })
    );
    dispatch(updateUserState({ key: 'backupPhoneLoading', value: false }));
    closeBackupModal();
  } catch (error) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Something went wrong! Please try again.',
      })
    );
    dispatch(updateUserState({ key: 'backupPhoneLoading', value: false }));
    return;
  }
};

export const saveVoicemailData = (action) => async (dispatch, getState) => {
  try {
    const state = getState().user;
    const appState = getState().app;
    const { file, blob, toggleModal } = action;
    if (!state.voicemailRecordingType) {
      dispatch(
        sendMessage({
          type: 'error',
          text: 'Please select voicemail recording type.',
        })
      );
      return;
    } else if (
      state.voicemailRecordingType === 'text' &&
      !state.voicemailTextRecording
    ) {
      dispatch(
        sendMessage({
          type: 'error',
          text: 'Please fill voicemail recording message.',
        })
      );
      return;
    }
    const formData = new FormData();
    if (state.voicemailRecordingType === 'file') {
      if (!file) {
        dispatch(
          sendMessage({
            type: 'error',
            text: 'Please select a voicemail recording file to upload.',
          })
        );
        return;
      }
      formData.append('mp3', file.blobFile);
      formData.append('username', appState.username);
      formData.append('password', appState.password);
    } else if (state.voicemailRecordingType === 'online') {
      if (!blob) {
        dispatch(
          sendMessage({
            type: 'error',
            text: 'Please record an audio to upload.',
          })
        );
        return;
      }
      formData.append('mp3', blob.blob);
      formData.append('username', appState.username);
      formData.append('password', appState.password);
    }
    let fileLocation = '';
    dispatch(updateUserState({ key: 'voicemailLoading', value: true }));
    if (state.voicemailRecordingType !== 'text') {
      const data = await axios.post(
        `${SERVER_URL}/files/uploadFromDialer`,
        formData
      );
      fileLocation = data?.data.location || '';
    }
    const data = {
      backupType: 'voicemail',
      voicemailRecordingType: state.voicemailRecordingType,
      voicemailTextRecording:
        state.voicemailRecordingType === 'text'
          ? state.voicemailTextRecording
          : '',
      voicemailFileRecording:
        state.voicemailRecordingType !== 'text' ? fileLocation : '',
      voicemailStatus: state.voicemailStatus,
    };

    await axios.post(`${SERVER_URL}/user/updateVoicemailData`, data);
    dispatch(
      sendMessage({
        type: 'success',
        text: 'Voicemail details updated successfully.',
      })
    );
    dispatch(updateUserState({ key: 'voicemailLoading', value: false }));
    toggleModal();
  } catch (error) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Something went wrong! Please try again.',
      })
    );
    dispatch(updateUserState({ key: 'voicemailLoading', value: false }));
    return;
  }
};

export default userSlice.reducer;
