import React, { useState, useEffect } from 'react';
import {
  Mic,
  MicOff,
  Pause,
  Play,
  UserPlus,
  Voicemail,
  Users,
  PhoneOff,
  MoreHorizontal,
  Hash,
  GitMerge,
  ArrowRight,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dropdown,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Whisper,
} from 'rsuite';
import {
  hangupCall,
  optOutCustomer,
  sendDigits,
  toggleClientHold,
  toggleHold,
  toggleMute,
  stopTimer,
  blockCustomer,
  clearCallInfo,
  callNextNumber,
  takeoverCall,
} from './callSlice';
import { colors, secondsToTimer } from '../utils';
import CallBugModal from './CallBugModal';
import DigitRenderer from '../Home/DigitRenderer';
import ParticipantModal from './ParticipantModal';
import VoicemailDropModal from './VoicemailDropModal';
import AddParticipantModal from './AddParticipantModal';
import AudioDeviceModal from '../Home/AudioDeviceModal';

const index = () => {
  const [participantModal, setParticipantModal] = useState(false);
  const [addParticipantModal, setAddParticipantModal] = useState(false);
  const [voicemailDropModal, setVoicemailDropModal] = useState(false);
  const [audioDeviceModal, setAudioDeviceModal] = useState(false);
  const [callBugModal, setCallBugModal] = useState(false);
  const state = useSelector((state) => state.call);

  const dispatch = useDispatch();

  const toggleParticipantModal = () => setParticipantModal(!participantModal);
  const toggleAddParticipantModal = () =>
    setAddParticipantModal(!addParticipantModal);
  const toggleVoicemailDropModal = () =>
    setVoicemailDropModal(!voicemailDropModal);
  const toggleAudioDeviceModal = () => setAudioDeviceModal(!audioDeviceModal);
  const toggleCallBugModal = () => setCallBugModal(!callBugModal);

  const renderButton = (
    disabled,
    label,
    Icon,
    color,
    fn,
    secondLabel,
    SecondIcon,
    state
  ) => (
    <Whisper
      placement='bottom'
      controlId='control-id-hover'
      trigger='hover'
      speaker={<Tooltip>{state ? secondLabel : label}</Tooltip>}
    >
      <IconButton
        style={{
          color: 'white',
          backgroundColor: color || 'blue',
        }}
        disabled={disabled}
        className='wobble'
        onClick={fn}
        icon={state ? <SecondIcon size={24} /> : <Icon size={24} />}
        circle
      />
    </Whisper>
  );

  useEffect(() => {
    return () => {
      dispatch(clearCallInfo());
      dispatch(stopTimer());
    };
  }, []);

  return (
    <div
      style={{
        ...colors.backgroundGradient,
        minHeight: '100%',
        maxHeight: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Stack
        direction='column'
        justifyContent='space-around'
        alignItems='center'
      >
        <p style={{ color: colors.secondary }}>
          Audio Issues? Update Mic/Speaker or Report an issue
        </p>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{
            width: '25rem',
          }}
        >
          <Button
            color='blue'
            onClick={toggleAudioDeviceModal}
            appearance='ghost'
            size='sm'
            disabled={state.callLoading}
          >
            Update Mic/Speaker
          </Button>
          <Button
            color='blue'
            onClick={toggleCallBugModal}
            appearance='ghost'
            size='sm'
            disabled={state.callLoading}
          >
            Report an Issue
          </Button>
        </Stack>
      </Stack>

      <Stack
        spacing={24}
        direction='column'
        justifyContent='space-around'
        alignItems='center'
        style={{
          minHeight: '92vh',
        }}
        className='call-screen-gradient'
      >
        <div>
          <h6
            style={{
              textAlign: 'center',
              letterSpacing: '0.1rem',
            }}
          >
            {state.direction === 'incoming' ? 'Incoming Call' : 'Outgoing Call'}
          </h6>
          <h1 style={{ color: colors.primary, letterSpacing: '0.2rem' }}>
            {state.phoneNumber}
          </h1>
          <h6
            style={{
              textAlign: 'center',
              color: colors.secondary,
              letterSpacing: '0.1rem',
            }}
          >
            Company Number: {state.companyNumber}
          </h6>
          <h4 style={{ textAlign: 'center', marginTop: '1rem' }}>
            {state.time ? secondsToTimer(state.time) : state.callState}
          </h4>
        </div>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ width: '30rem' }}
        >
          {renderButton(
            state.callLoading,
            state.isSupervisor ? 'Mute/Whisper' : 'Mute',
            Mic,
            '',
            () => dispatch(toggleMute()),
            state.isSupervisor ? 'Unmute/Whisper' : 'Unmute',
            MicOff,
            state.mute
          )}
          {renderButton(
            state.callLoading,
            'Hold',
            Pause,
            '',
            () => dispatch(toggleHold()),
            'Unhold',
            Play,
            state.hold
          )}

          <Whisper
            placement='top'
            controlId='control-id-click'
            trigger='click'
            speaker={
              <Popover>
                <DigitRenderer
                  clickHandler={(digit) => dispatch(sendDigits(digit))}
                />
              </Popover>
            }
          >
            <IconButton
              style={{
                color: 'white',
                backgroundColor: 'blue',
              }}
              disabled={state.callLoading}
              className='wobble'
              icon={<Hash size={24} />}
              circle
            />
          </Whisper>

          {state.isSupervisor
            ? renderButton(state.callLoading, 'Takeover', GitMerge, '', () =>
                dispatch(takeoverCall())
              )
            : ''}

          {renderButton(
            state.callLoading,
            'Add Participant',
            UserPlus,
            '',
            toggleAddParticipantModal
          )}
          {renderButton(
            state.callLoading,
            'Participants',
            Users,
            '',
            toggleParticipantModal
          )}
          {renderButton(
            state.callLoading,
            'Drop Voicemail',
            Voicemail,
            '',
            toggleVoicemailDropModal
          )}
          <Whisper
            placement='bottom'
            controlId='control-id-hover'
            trigger='hover'
            speaker={<Tooltip>More</Tooltip>}
          >
            <Dropdown
              noCaret
              placement='topEnd'
              renderToggle={(props, ref) => (
                <IconButton
                  {...props}
                  ref={ref}
                  style={{
                    color: 'white',
                    backgroundColor: 'blue',
                  }}
                  disabled={state.callLoading}
                  icon={<MoreHorizontal size={24} />}
                  circle
                />
              )}
            >
              <Dropdown.Item onClick={() => dispatch(toggleClientHold())}>
                {state.clientHold ? 'Unhold Caller' : 'Hold Caller'}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => dispatch(optOutCustomer())}>
                Opt Out Caller
              </Dropdown.Item>
              <Dropdown.Item onClick={() => dispatch(blockCustomer())}>
                Block Caller
              </Dropdown.Item>
            </Dropdown>
          </Whisper>
          {state.isProgressive
            ? renderButton(
                state.callLoading,
                'Call Next Number',
                ArrowRight,
                '',
                () => dispatch(callNextNumber())
              )
            : ''}
          {renderButton(state.callLoading, 'Hangup', PhoneOff, 'red', () =>
            dispatch(hangupCall())
          )}
        </Stack>
      </Stack>
      <AddParticipantModal
        modelState={addParticipantModal}
        toggleModal={toggleAddParticipantModal}
      />
      <ParticipantModal
        modelState={participantModal}
        toggleModal={toggleParticipantModal}
      />
      <VoicemailDropModal
        modelState={voicemailDropModal}
        toggleModal={toggleVoicemailDropModal}
      />
      <AudioDeviceModal
        toggleModal={toggleAudioDeviceModal}
        modelState={audioDeviceModal}
      />
      <CallBugModal
        toggleModal={toggleCallBugModal}
        modelState={callBugModal}
      />
    </div>
  );
};

export default index;
