import React, { useState } from 'react';
import { Button, Stack, Input, Dropdown, InputGroup } from 'rsuite';
import DigitRenderer from './DigitRenderer';
import { Delete, PhoneCall } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { makeCall } from '../OngoingCall/callSlice';

const RightSidebar = ({
  toggleBackupModal,
  toggleVoicemailModal,
  toggleAudioDeviceModal,
  toggleProgressiveListModal,
}) => {
  const appState = useSelector((state) => state.app);
  const state = useSelector((state) => state.home);
  const [callingNumber, setCallingNumber] = useState('');

  const dispatch = useDispatch();

  const handleDigit = (digit) => {
    const newNumber = callingNumber + digit;
    setCallingNumber(newNumber);
  };

  return (
    <Stack
      spacing={24}
      direction='column'
      alignItems='center'
      justifyContent='space-between'
      style={{ height: '87vh' }}
    >
      <Stack
        spacing={6}
        direction='column'
        alignItems='center'
        justifyContent='space-between'
      >
        <Button
          color='blue'
          onClick={toggleVoicemailModal}
          appearance='ghost'
          size='md'
        >
          Update Voicemail
        </Button>
        <Button
          color='blue'
          onClick={toggleBackupModal}
          appearance='ghost'
          size='md'
        >
          Update Backup Phone
        </Button>
        <Button
          color='blue'
          onClick={toggleAudioDeviceModal}
          appearance='ghost'
          size='md'
        >
          Update Audio Device
        </Button>
        <Button
          color='green'
          onClick={toggleProgressiveListModal}
          appearance='ghost'
          size='md'
        >
          Join Progressive Dialer
        </Button>
      </Stack>
      <Stack
        spacing={4}
        direction='column'
        alignItems='center'
        justifyContent='space-between'
      >
        <InputGroup inside>
          <Input
            placeholder='Enter Number Here'
            size='sm'
            value={callingNumber}
            onChange={(value) => setCallingNumber(value)}
          />
          <InputGroup.Button
            disabled={!callingNumber}
            style={{ maxHeight: '100%' }}
          >
            <Delete
              onClick={() =>
                setCallingNumber((callingNumber) => callingNumber.slice(0, -1))
              }
            />
          </InputGroup.Button>
        </InputGroup>
        <div>
          <DigitRenderer clickHandler={handleDigit} />
        </div>

        <Dropdown
          noCaret
          placement='topStart'
          menuStyle={{ maxHeight: '15rem', overflowY: 'scroll' }}
          renderToggle={(props, ref) => (
            <Button
              color='green'
              appearance='primary'
              {...props}
              ref={ref}
              size='sm'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '8rem',
                outline: 'none',
              }}
            >
              <PhoneCall size={16} style={{ marginRight: '0.4rem' }} />
              <span>Call</span>
            </Button>
          )}
        >
          {appState.phoneNumbers.map((availableNumber, index) => (
            <Dropdown.Item
              key={index}
              onClick={() =>
                dispatch(
                  makeCall({
                    number: callingNumber,
                    outgoingNumber: availableNumber.number,
                  })
                )
              }
            >
              {availableNumber.number}
            </Dropdown.Item>
          ))}
        </Dropdown>

        <Dropdown
          noCaret
          placement='topStart'
          menuStyle={{ maxHeight: '15rem', overflowY: 'scroll' }}
          renderToggle={(props, ref) => (
            <Button
              color='green'
              appearance='primary'
              {...props}
              ref={ref}
              size='sm'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '8rem',
                outline: 'none',
              }}
            >
              <PhoneCall size={16} style={{ marginRight: '0.4rem' }} />
              <span>Redial</span>
            </Button>
          )}
        >
          {state.callLogs.map(
            (callLog, index) =>
              index < 5 && (
                <Dropdown.Item
                  key={index}
                  onClick={() =>
                    dispatch(
                      makeCall({
                        number: callLog.destination_number,
                        outgoingNumber: callLog.companyNumber,
                      })
                    )
                  }
                >
                  {callLog.destination_number}
                </Dropdown.Item>
              )
          )}
        </Dropdown>
      </Stack>
    </Stack>
  );
};

export default RightSidebar;
