import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import { SERVER_URL } from '../utils';
import { sendMessage } from '../App/appSlice';

const initialState = {
  callerId: '',
  phoneNumber: '',
  companyNumber: '',
  callUUID: '',
  direction: '',
  messageLoading: false,
};

export const incomingSlice = createSlice({
  name: 'incomingCall',
  initialState,
  reducers: {
    updateIncomingState: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    updateIncomingCallInfo: (state, action) => {
      state.callerId = action.payload.callerId;
      state.phoneNumber = action.payload.phoneNumber;
      state.companyNumber = action.payload.companyNumber;
      state.callUUID = action.payload.callUUID;
      state.direction = action.payload.direction;
    },
    clearIncomingCallInfo: (state, action) => {
      state.callerId = '';
      state.phoneNumber = '';
      state.companyNumber = '';
      state.callUUID = '';
      state.direction = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateIncomingCallInfo,
  clearIncomingCallInfo,
  updateIncomingState,
} = incomingSlice.actions;

export const answerCall = (action) => async (dispatch, getState) => {
  try {
    plivoBrowserSdk.client.answer();
  } catch (err) {
    return null;
  }
};

export const rejectCall = (action) => async (dispatch, getState) => {
  try {
    const incomingCallState = getState().incomingCall;
    plivoBrowserSdk.client.reject(incomingCallState.callUUID);
    // dispatch(updatePage('home'));
  } catch (err) {
    return null;
  }
};

export const rejectWithMessage = (action) => async (dispatch, getState) => {
  try {
    const appState = getState().app;
    const state = getState().incomingCall;
    dispatch(updateIncomingState({ key: 'messageLoading', value: true }));
    await axios.post(`${SERVER_URL}/call/incoming/rejectWithMessage`, {
      phoneNumber: state.phoneNumber,
      companyNumber: state.companyNumber,
      message: action,
    });
    dispatch(updateIncomingState({ key: 'messageLoading', value: false }));
    dispatch(
      sendMessage({ type: 'success', text: 'Message Sent Successfully.' })
    );
    dispatch(rejectCall());
  } catch (err) {
    dispatch(updateIncomingState({ key: 'messageLoading', value: false }));
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Error Sending Message. Reject Call...',
      })
    );
    dispatch(rejectCall());
  }
};

export default incomingSlice.reducer;
