import React, { useRef } from 'react';
import { Pause, Play } from 'react-feather';
import { useSelector } from 'react-redux';
import { Col, FlexboxGrid, Grid, IconButton, Panel, Row, Stack } from 'rsuite';
import { colors } from '../utils';

const dataRenderer = (label, value) => {
  return (
    <Panel shaded bordered bodyFill style={{ margin: '0.5rem 0' }}>
      <Row align='center' justify='center'>
        <Col sm={12}>
          <h6 style={{ lineHeight: '2rem', fontWeight: 'normal' }}>{label}</h6>
        </Col>
        <Col sm={12}>
          <h6
            style={{
              letterSpacing: '0.05rem',
              lineHeight: '2rem',
              color: colors.secondary,
            }}
          >
            {value}
          </h6>
        </Col>
      </Row>
    </Panel>
  );
};

const MiddleScreen = () => {
  const { selectedLog, selectedIndex, bitrixName } = useSelector(
    (state) => state.home
  );
  let audioEl = useRef(null);

  if (selectedIndex < 0)
    return (
      <FlexboxGrid
        align='middle'
        justify='center'
        style={{ minHeight: '90vh' }}
      >
        <h3>No Record Selected</h3>
      </FlexboxGrid>
    );

  return (
    <>
      <h3
        style={{
          width: '9rem',
          fontWeight: 'lighter',
          marginBottom: '1rem',
          borderBottom: `3px solid ${colors.primary}`,
        }}
      >
        Call Details
      </h3>
      <Stack
        spacing={4}
        justifyContent='space-between'
        alignItems='center'
        direction='column'
      >
        <h3 style={{ color: colors.primary, fontWeight: 'bold' }}>
          {selectedLog.destination_number}
        </h3>
      </Stack>
      <Grid
        fluid
        align='center'
        style={{ overflowY: 'scroll', maxHeight: '70vh' }}
      >
        {dataRenderer('Company Number', selectedLog.companyNumber)}
        {dataRenderer('Name', bitrixName)}
        {dataRenderer(
          'Date',
          new Date(selectedLog.start_time || '-').toLocaleString()
        )}
        {dataRenderer('Duration', `${selectedLog.duration} sec`)}
        {dataRenderer('Agents List', selectedLog.employeesName)}
        <Panel shaded bordered bodyFill style={{ margin: '0.5rem 0' }}>
          <Row>
            <Col sm={12}>
              <h6
                style={{
                  letterSpacing: '0.05rem',
                  lineHeight: '3rem',
                  fontWeight: 'normal',
                }}
              >
                Recording
              </h6>
            </Col>
            <Col sm={12}>
              <h6
                style={{
                  letterSpacing: '0.05rem',
                  lineHeight: '3rem',
                  color: colors.secondary,
                }}
              >
                {selectedLog.recordingUrl ? (
                  <Stack justifyContent='space-around' alignItems='center'>
                    <audio ref={audioEl} src={selectedLog.recordingUrl} />
                    <IconButton
                      icon={<Play />}
                      size='xs'
                      onClick={() => audioEl.current.play()}
                    />
                    <IconButton
                      icon={<Pause />}
                      size='xs'
                      onClick={() => audioEl.current.pause()}
                    />
                  </Stack>
                ) : (
                  'N/A'
                )}
              </h6>
            </Col>
          </Row>
        </Panel>
      </Grid>
    </>
  );
};

export default MiddleScreen;
