import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Progress, SelectPicker, Stack } from 'rsuite';
import {
  fetchDeviceDetails,
  saveAudioDevices,
  saveMic,
  saveSpeaker,
} from './audioDeviceSlice';

const AudioDeviceModal = ({ toggleModal, modelState }) => {
  const state = useSelector((state) => state.audioDevice);
  const [volumeInterval, setVolumeInterval] = useState(null);
  const [speakerTest, setSpeakerTest] = useState(false);
  const [micVolume, setMicVolume] = useState(0);
  const dispatch = useDispatch();
  let audioEl = useRef(null);

  useEffect(() => {
    dispatch(fetchDeviceDetails());
    return () => {
      if (volumeInterval) {
        clearInterval(volumeInterval);
        setVolumeInterval(null);
      }
    };
  }, []);

  const volumeCallback = (analyser, volumes) => {
    analyser.getByteFrequencyData(volumes);
    let volumeSum = 0;
    for (const volume of volumes) volumeSum += volume;
    const averageVolume = volumeSum / volumes.length;
    setMicVolume(() => parseInt((averageVolume * 100) / 127));
  };

  const toggleMicrophoneTest = async () => {
    if (volumeInterval) {
      clearInterval(volumeInterval);
      setVolumeInterval(null);
      setMicVolume(0);
    } else {
      await testMicrophone();
    }
  };

  const testMicrophone = async () => {
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
      },
    });
    const audioContext = new AudioContext();
    const audioSource = audioContext.createMediaStreamSource(audioStream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 512;
    analyser.minDecibels = -127;
    analyser.maxDecibels = 0;
    analyser.smoothingTimeConstant = 0.4;
    audioSource.connect(analyser);
    const volumes = new Uint8Array(analyser.frequencyBinCount);
    setVolumeInterval(
      setInterval(() => volumeCallback(analyser, volumes), 100)
    );
  };

  const toggleSpeakerTest = () => {
    if (speakerTest) {
      audioEl.current.pause();
      audioEl.current.remove();
      setSpeakerTest(false);
    } else {
      audioEl.current.controls = false;
      audioEl.current.src =
        'https://ustelephony24.s3.amazonaws.com/mp3/onhold.mp3';
      audioEl.current.play();
      setSpeakerTest(true);
    }
  };

  const getStrokeColor = (micPercent) => {
    if (micPercent === 0) {
      return 'red';
    } else if (micPercent < 30) {
      return '#ffc107';
    } else if (micPercent < 70) {
      return '#3385ff';
    } else {
      return '#52c41a';
    }
  };

  const clearUI = () => {
    if (volumeInterval) {
      clearInterval(volumeInterval);
    }
    setVolumeInterval(null);
    setMicVolume(0);
    audioEl.current.pause();
    audioEl.current.remove();
    setSpeakerTest(false);
  };

  return (
    <Modal open={modelState} onClose={toggleModal} onExiting={clearUI}>
      <Modal.Header>
        <Modal.Title>Update Audio Device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <audio ref={audioEl} />
        <Stack
          spacing={24}
          direction='column'
          alignItems='center'
          justifyContent='space-between'
        >
          <Stack
            justifyContent='space-between'
            alignItems='center'
            direction='column'
          >
            <Stack
              justifyContent='space-around'
              alignItems='center'
              style={{ width: '30rem' }}
            >
              <SelectPicker
                data={state.availableMics}
                size='md'
                style={{ width: '20rem' }}
                onChange={(value) => dispatch(saveMic(value))}
                value={state.micId}
              />
              <Button onClick={toggleMicrophoneTest} appearance='ghost'>
                {volumeInterval ? 'Stop Test' : 'Start Test'}
              </Button>
            </Stack>
            <div style={{ width: '25rem', marginTop: '1rem' }}>
              <Progress.Line
                percent={micVolume}
                showInfo={false}
                strokeColor={getStrokeColor(micVolume)}
                status={state.updatePercent === 100 ? 'success' : 'active'}
              />
            </div>
          </Stack>

          <Stack
            justifyContent='space-around'
            alignItems='center'
            style={{ width: '30rem' }}
          >
            <SelectPicker
              data={state.availableSpeakers}
              size='md'
              style={{ width: '20rem' }}
              onChange={(value) => dispatch(saveSpeaker(value))}
              value={state.speakerId}
            />
            <Button onClick={toggleSpeakerTest} appearance='ghost'>
              {speakerTest ? 'Stop Test' : 'Start Test'}
            </Button>
          </Stack>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default AudioDeviceModal;
