import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Loader, Modal, Stack } from 'rsuite';
import { rejectWithMessage } from './incomingSlice';

const MessageModal = ({ modelState, toggleModal }) => {
  const [message, setMessage] = useState('');
  const state = useSelector((state) => state.incomingCall);
  const dispatch = useDispatch();
  return (
    <Modal open={modelState} onClose={toggleModal} size='sm'>
      <Modal.Header>
        <Modal.Title>Reject with Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {state.messageLoading ? (
          <Loader backdrop content='loading...' vertical />
        ) : (
          <Stack
            spacing={24}
            direction='column'
            alignItems='center'
            justifyContent='space-between'
          >
            <Input
              as='textarea'
              rows={4}
              style={{ width: '25rem' }}
              placeholder='Message'
              value={message}
              onChange={(value) => setMessage(value)}
            />
          </Stack>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => dispatch(rejectWithMessage(message))}
          appearance='primary'
          disabled={state.messageLoading}
        >
          Send
        </Button>
        <Button
          onClick={toggleModal}
          appearance='subtle'
          disabled={state.messageLoading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;
