import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, SelectPicker, Stack, Input, Loader } from 'rsuite';
import { changePassword } from './homeSlice';

const ChangePasswordModal = ({ toggleModal, modelState }) => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const state = useSelector((state) => state.home);
  const dispatch = useDispatch();

  const handleChangePassword = async () => {
    dispatch(
      changePassword({ password, newPassword, confirmPassword, toggleModal })
    );
  };

  return (
    <Modal open={modelState} onClose={toggleModal} size='sm'>
      <Modal.Header>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {state.changePasswordLoading ? (
          <Loader backdrop content='loading...' vertical />
        ) : (
          <Stack
            spacing={24}
            direction='column'
            alignItems='center'
            justifyContent='space-between'
            style={{ width: '100%' }}
          >
            <Stack
              spacing={12}
              justifyContent='space-between'
              alignItems='center'
              style={{ width: '100%' }}
            >
              <Input
                style={{ width: '15rem' }}
                type='password'
                autoComplete='off'
                placeholder='Current Password'
                onChange={(value) => setPassword(value)}
              />
            </Stack>
            <Stack
              spacing={12}
              justifyContent='space-between'
              alignItems='center'
              style={{ width: '100%' }}
            >
              <Input
                style={{ width: '15rem' }}
                type='password'
                autoComplete='off'
                placeholder='New Password'
                onChange={(value) => setNewPassword(value)}
              />
              <Input
                style={{ width: '15rem' }}
                type='password'
                autoComplete='off'
                placeholder='Confirm Password'
                onChange={(value) => setConfirmPassword(value)}
              />
            </Stack>
          </Stack>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleChangePassword}
          appearance='primary'
          disabled={state.changePasswordLoading}
        >
          Update
        </Button>
        <Button
          onClick={toggleModal}
          appearance='subtle'
          disabled={state.changePasswordLoading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
