import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import { SERVER_URL } from '../utils';
import { Message, toaster } from 'rsuite';
import { updateHomeState } from '../Home/homeSlice';
import { initAxios } from '../helpers';

const initialState = {
  username: JSON.parse(localStorage.getItem('ustelephony24'))?.username || '',
  email: JSON.parse(localStorage.getItem('ustelephony24'))?.email || '',
  password: JSON.parse(localStorage.getItem('ustelephony24'))?.password || '',
  name: JSON.parse(localStorage.getItem('ustelephony24'))?.name || '',
  token: JSON.parse(localStorage.getItem('ustelephony24'))?.token || '',
  phoneNumbers:
    JSON.parse(localStorage.getItem('ustelephony24'))?.phoneNumbers || [],
  updatePercentage: '',
  updateAvailable: false,
  plivoBrowserSdk: null,
  pageState: 'login',
  loading: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      const { updateMessage, percentage } = action.payload;
      if (updateMessage === 'Update is available') {
        state.updateAvailable = true;
        state.updatePercentage = '0%';
      }
      if (percentage) {
        state.percentage = percentage;
      }
    },

    updateAppState: (state, action) => {
      const { type, value } = action.payload;
      state[type] = value;
    },

    updateUserDetails: (state, action) => {
      const { name, email, username, password, phoneNumbers, token } =
        action.payload;
      state.name = name;
      state.email = email;
      state.username = username;
      state.password = password;
      state.phoneNumbers = phoneNumbers;
      state.token = token;
    },

    updatePage: (state, action) => {
      state.pageState = action.payload;
    },

    updateLogout: (state, action) => {
      state.name = '';
      state.email = '';
      state.username = '';
      state.password = '';
      state.phoneNumbers = [];
      state.token = '';
      state.pageState = 'login';
    },

    updateLoading: (state, action) => {
      state.loading = action.payload;
    },

    updatePhoneNumbers: (state, action) => {
      state.phoneNumbers = action.payload;
      const ustDetails = JSON.parse(localStorage.getItem('ustelephony24'));
      const updatedUstDetails = {
        ...ustDetails,
        phoneNumbers: action.payload,
      };
      localStorage.setItem('ustelephony24', JSON.stringify(updatedUstDetails));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateCallInfo,
  updateMessage,
  updateMute,
  updateLogin,
  updateAppState,
  updatePhoneNumbers,
  updatePage,
  updateUserDetails,
  updateLogout,
  updateLoading,
} = appSlice.actions;

export const handleLogin = (action) => async (dispatch, getState) => {
  try {
    const state = getState().app;
    let ustDetails = null;
    if (state.username) {
      ustDetails = {
        username: state.username,
        email: state.email,
        password: state.password,
        name: state.name,
        phoneNumbers: state.phoneNumbers,
        token: state?.token,
      };
    } else {
      const storedUstDetails = localStorage
        .getItem('ustelephony24')
        ?.JSON.parse(localStorage.getItem('ustelephony24'));
      if (storedUstDetails) {
        ustDetails = {
          username: storedUstDetails.username,
          email: storedUstDetails.email,
          password: storedUstDetails.password,
          name: storedUstDetails.name,
          phoneNumbers: storedUstDetails.phoneNumbers,
          token: storedUstDetails?.token,
        };
      }
    }
    if (!ustDetails || !ustDetails.token) {
      dispatch(
        sendMessage({
          type: 'error',
          text: 'Session expired, Please login again.',
        })
      );
      dispatch(logout());
      return;
    }

    const { username, password, token } = ustDetails;
    initAxios(token);
    if (!plivoBrowserSdk) {
      dispatch(
        sendMessage({
          type: 'error',
          text: 'App not ready! Please try reloading the app.',
        })
      );
      return;
    }
    const loginResponse = await plivoBrowserSdk.client.login(
      username,
      password
    );
    if (!loginResponse) {
      dispatch(
        sendMessage({
          type: 'error',
          text: 'Something went wrong, please try again.',
        })
      );
      return null;
    }
    localStorage.setItem('ustelephony24', JSON.stringify(action));
    dispatch(updateUserDetails(ustDetails));
  } catch (error) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Something went wrong, please try again.',
      })
    );
    return;
  }
};

export const logout = (action) => async (dispatch, getState) => {
  try {
    dispatch(updateHomeState({ key: 'logoutLoading', value: true }));
    // If plivo Logs out, change primary endpoint
    await axios.post(`${SERVER_URL}/user/logoutUser`, {
      type: 'desktop',
    });
    await plivoBrowserSdk.client.logout();
  } catch (error) {
    dispatch(updateHomeState({ key: 'logoutLoading', value: false }));
  } finally {
    localStorage.removeItem('ustelephony24');
    dispatch(updateHomeState({ key: 'logoutLoading', value: false }));
    dispatch(updateLogout());
  }
};

export const rejectCall = (action) => async (dispatch, getState) => {
  if (action.rejectedCallUUID) {
    plivoBrowserSdk.client.reject(action.rejectedCallUUID);
  } else {
    plivoBrowserSdk.client.reject();
  }
};

export const fetchNumbers = (action) => async (dispatch, getState) => {
  try {
    // Update numbers in case of admin adds new number
    const phoneNumbersResponse = await axios.post(
      `${SERVER_URL}/user/getNumbersForDialer`
    );
    dispatch(updatePhoneNumbers(phoneNumbersResponse.data.payload || []));
  } catch (error) {}
};

export const sendMessage = (action) => (dispatch, getState) => {
  const { type, text } = action;
  console.log(type, text);
  const message = (
    <Message
      style={{
        marginLeft: '-155vw',
      }}
      duration={type === 'error' ? 7000 : 4000}
      showIcon
      type={type}
    >
      {text}
    </Message>
  );
  toaster.push(message, { placement: 'topCenter' });
};

export default appSlice.reducer;
