import React, { useEffect, useState } from 'react';
import { Col, Row, Grid, Panel, Loader } from 'rsuite';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import Header from './Header';
import MiddleScreen from './MiddleScreen';
import BackupPhoneModal from './BackupPhoneModal';
import VoicemailModal from './VoicemailModal';
import AudioDeviceModal from './AudioDeviceModal';
import ChangePasswordModal from './ChangePasswordModal';
import { useDispatch, useSelector } from 'react-redux';
import { getCallLogs } from './homeSlice';
import ProgressiveListModal from './ProgressiveListModal';

const Home = () => {
  const [backupPhoneModal, setBackupPhoneModal] = useState(false);
  const [voicemailModal, setVoicemailModal] = useState(false);
  const [audioDeviceModal, setAudioDeviceModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [progressiveListModal, setProgressiveListModal] = useState(false);

  const state = useSelector((state) => state.home);

  const dispatch = useDispatch();

  const toggleBackupModal = () => setBackupPhoneModal(!backupPhoneModal);
  const toggleVoicemailModal = () => setVoicemailModal(!voicemailModal);
  const toggleAudioDeviceModal = () => setAudioDeviceModal(!audioDeviceModal);
  const toggleChangePasswordModal = () =>
    setChangePasswordModal(!changePasswordModal);
  const toggleProgressiveListModal = () =>
    setProgressiveListModal(!progressiveListModal);

  useEffect(() => {
    dispatch(getCallLogs());
  }, []);

  if (state.logoutLoading) {
    return <Loader backdrop content='loading...' vertical />;
  }

  return (
    <>
      <Grid
        fluid
        style={{ overflow: 'hidden', maxHeight: '98.4vh', margin: '0.2rem' }}
      >
        <Header toggleChangePasswordModal={toggleChangePasswordModal} />

        <Row gutter={0}>
          <Col sm={8}>
            <Panel
              shaded
              bordered
              bodyFill
              style={{ padding: '0.3rem', height: '90vh' }}
            >
              <LeftSidebar />
            </Panel>
          </Col>
          <Col sm={10}>
            <Panel
              shaded
              bordered
              bodyFill
              style={{
                padding: '0.3rem',
                marginLeft: '0.2rem',
                height: '90vh',
              }}
            >
              <MiddleScreen />
            </Panel>
          </Col>
          <Col sm={6}>
            <Panel
              shaded
              bordered
              bodyFill
              style={{
                padding: '0.3rem',
                marginLeft: '0.2rem',
                height: '90vh',
              }}
            >
              <RightSidebar
                toggleBackupModal={toggleBackupModal}
                toggleVoicemailModal={toggleVoicemailModal}
                toggleAudioDeviceModal={toggleAudioDeviceModal}
                toggleProgressiveListModal={toggleProgressiveListModal}
              />
            </Panel>
          </Col>
        </Row>
      </Grid>
      <BackupPhoneModal
        toggleModal={toggleBackupModal}
        modelState={backupPhoneModal}
      />
      <VoicemailModal
        toggleModal={toggleVoicemailModal}
        modelState={voicemailModal}
      />
      <AudioDeviceModal
        toggleModal={toggleAudioDeviceModal}
        modelState={audioDeviceModal}
      />
      <ChangePasswordModal
        toggleModal={toggleChangePasswordModal}
        modelState={changePasswordModal}
      />
      <ProgressiveListModal
        toggleModal={toggleProgressiveListModal}
        modelState={progressiveListModal}
      />
    </>
  );
};

export default Home;
