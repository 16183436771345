import { useEffect } from 'react';
import Plivo from 'plivo-browser-sdk';
import { useSelector, useDispatch } from 'react-redux';
import io from 'socket.io-client';

import Home from '../Home';
import Login from '../Login';
import OngoingCall from '../OngoingCall';
import IncomingCall from '../IncomingCall';
import {
  fetchNumbers,
  handleLogin,
  logout,
  sendMessage,
  updatePage,
} from './appSlice';
import {
  answerCall,
  clearIncomingCallInfo,
  updateIncomingCallInfo,
} from '../IncomingCall/incomingSlice';
import {
  clearCallInfo,
  startTimer,
  stopTimer,
  updateCallInfo,
  updateCallState,
  updateSupervisorState,
} from '../OngoingCall/callSlice';
import { getCallLogs, makeDeviceActive } from '../Home/homeSlice';
import { checkAudioDevice, updateLoginState } from '../Login/loginSlice';
import { Stack } from 'rsuite';
import { colors } from '../utils';
import Header from '../Login/LoginHeader';

const App = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.app);

  const onWebrtcNotSupported = () => {
    console.warn('no webRTC support');
    alert(
      'Application Not Supported, Please contact at support@ustelephony24.com'
    );
  };

  const onLogin = async () => {
    const socketCon = io('https://api.ustelephony24.com', {
      transports: ['websocket'],
      query: {
        Authorization: `Bearer ${state.token}`,
      },
      autoConnect: true,
    });
    socketCon.on('connect', async () => {
      socketCon.emit('join', { username: state.username });
      dispatch(
        sendMessage({ type: 'success', text: 'Call notifications enabled.' })
      );
      await checkPlivoLogin();
    });
    socketCon.on('updateCallState', ({ callStatus }) => {
      if (callStatus === 'Answered') {
        dispatch(startTimer());
      } else {
        dispatch(updateCallState({ key: 'callState', value: callStatus }));
      }
    });
    socketCon.on('updatePhoneNumber', () => {
      dispatch(fetchNumbers());
    });
    socketCon.on('sendMessage', (messageData) => {
      dispatch(sendMessage(messageData));
    });
    socketCon.on('userRemoved', () => {
      dispatch(logout());
    });

    socketCon.on('disconnect', () => {
      dispatch(
        sendMessage({ type: 'warning', text: 'Call notifications disabled.' })
      );
    });
    window.ustSocketCon = socketCon;
    dispatch(checkAudioDevice());
    dispatch(sendMessage({ type: 'success', text: 'Logged in.' }));
    await dispatch(makeDeviceActive());
    dispatch(updateLoginState({ key: 'loginLoading', value: false }));
    dispatch(updatePage('home'));
  };
  const onLogout = async () => {
    dispatch(sendMessage({ type: 'success', text: 'Logged out.' }));
    if (window.ustSocketCon) {
      window.ustSocketCon.disconnect();
      window.ustSocketCon = undefined;
    }
  };
  const onLoginFailed = (reason) => {
    localStorage.removeItem('ustelephony24');
    console.info('onLoginFailed ', reason);
    const errorMessage =
      reason === 'Authentication Error'
        ? 'Username or password incorrect.'
        : 'Something went wrong, please try again';
    dispatch(sendMessage({ type: 'error', text: errorMessage }));
    dispatch(updateLoginState({ key: 'loginLoading', value: false }));
  };
  const onCallRemoteRinging = (callInfo) => {
    // console.info('onCallRemoteRinging');
    // console.log('onCallRemoteRinging', callInfo);
  };

  const onConnectionChange = (e) => {
    // if (e.state === 'disconnected') {
    //   await checkPlivoLogin();
    // }
  };

  const onIncomingCallCanceled = async () => {
    dispatch(clearIncomingCallInfo());
    dispatch(clearCallInfo());
    dispatch(stopTimer());
    dispatch(updatePage('home'));
    dispatch(getCallLogs());
  };

  const onCallFailed = async (reason) => {
    dispatch(clearIncomingCallInfo());
    dispatch(clearCallInfo());
    dispatch(stopTimer());
    dispatch(updatePage('home'));
    dispatch(getCallLogs());
  };

  const onCallTerminated = async () => {
    dispatch(clearIncomingCallInfo());
    dispatch(clearCallInfo());
    dispatch(stopTimer());
    dispatch(updatePage('home'));
    dispatch(getCallLogs());
  };

  const onCallAnswered = async (callInfo) => {
    // console.log('answered', callInfo);
    const isOutgoing =
      callInfo.extraHeaders['X-Ph-Outgoing'] ||
      callInfo.direction === 'outgoing';
    if (callInfo.extraHeaders['X-Ph-Supervisor']) {
      dispatch(updateSupervisorState());
      dispatch(updateCallState({ key: 'mute', value: true }));
      dispatch(startTimer());
    }
    dispatch(
      updateCallInfo({
        phoneNumber:
          callInfo.direction === 'outgoing' ? callInfo.dest : callInfo.src,
        direction: isOutgoing ? 'outgoing' : 'incoming',
        callUUID: callInfo.callUUID,
        companyNumber: callInfo.extraHeaders['X-Ph-Companynumber'],
        isProgressive: callInfo.extraHeaders['X-Ph-Progressiveid'] || false,
        progressiveId: callInfo.extraHeaders['X-Ph-Progressiveid'] || '',
        mpcName: callInfo.extraHeaders['X-Ph-Mpcname'] || '',
      })
    );
    dispatch(updatePage('call'));
    if (!isOutgoing) {
      dispatch(startTimer());
    }
  };

  const onCalling = (data) => {
    // dispatch(updateCallInfo({ type: 'initiate' }));
  };

  const onIncomingCall = async (callerName, extraHeaders, callInfo) => {
    // console.log('incoming', callInfo);
    const isOutgoing =
      callInfo.extraHeaders['X-Ph-Outgoing'] ||
      callInfo.direction === 'outgoing';

    dispatch(
      updateIncomingCallInfo({
        phoneNumber: callInfo.src,
        direction: isOutgoing ? 'outgoing' : 'incoming',
        callUUID: callInfo.callUUID,
        companyNumber: extraHeaders['X-Ph-Companynumber'],
        isProgressive: extraHeaders['X-Ph-Progressiveid'] || false,
        progressiveId: extraHeaders['X-Ph-Progressiveid'] || '',
        mpcName: extraHeaders['X-Ph-Mpcname'] || '',
      })
    );
    if (extraHeaders['X-Ph-Outgoing'] === 'true') {
      dispatch(answerCall());
    } else {
      dispatch(updatePage('incoming'));
    }
  };

  const onMediaPermission = (evt) => {
    if (evt.status === 'failure') {
      dispatch(
        sendMessage({ type: 'error', text: 'Media permission not granted.' })
      );
      // Media permission not granted
    }
  };

  const mediaMetrics = (obj) => {
    console.table([obj]);
  };

  const audioDeviceChange = async (e) => {
    if (e.device.kind === 'audioinput') {
      await plivoBrowserSdk.client.audio.microphoneDevices.set('default');
      dispatch(
        sendMessage({
          type: 'success',
          text: 'Microphone Device set to default',
        })
      );
    } else {
      await plivoBrowserSdk.client.audio.speakerDevices.set('default');
      await plivoBrowserSdk.client.audio.ringtoneDevices.set('default');
      dispatch(
        sendMessage({ type: 'success', text: 'Speaker Device set to default' })
      );
    }
  };

  const loadPlivo = () => {
    const plivoBrowserSdk = new Plivo();
    plivoBrowserSdk.client.on('onWebrtcNotSupported', onWebrtcNotSupported);
    plivoBrowserSdk.client.on('onLogin', onLogin);
    plivoBrowserSdk.client.on('onLogout', onLogout);
    plivoBrowserSdk.client.on('onLoginFailed', onLoginFailed);
    plivoBrowserSdk.client.on('onCallRemoteRinging', onCallRemoteRinging);
    plivoBrowserSdk.client.on('onIncomingCallCanceled', onIncomingCallCanceled);
    plivoBrowserSdk.client.on('onCallFailed', onCallFailed);
    plivoBrowserSdk.client.on('onCallAnswered', onCallAnswered);
    plivoBrowserSdk.client.on('onCallTerminated', onCallTerminated);
    plivoBrowserSdk.client.on('onCalling', onCalling);
    plivoBrowserSdk.client.on('onIncomingCall', onIncomingCall);
    plivoBrowserSdk.client.on('onMediaPermission', onMediaPermission);
    plivoBrowserSdk.client.on('mediaMetrics', mediaMetrics);
    plivoBrowserSdk.client.on('audioDeviceChange', audioDeviceChange);
    plivoBrowserSdk.client.on('onConnectionChange', onConnectionChange);
    plivoBrowserSdk.client.setRingTone(true);
    plivoBrowserSdk.client.setRingToneBack(true);
    window.plivoBrowserSdk = plivoBrowserSdk;
  };

  const checkPlivoLogin = async () => {
    if (!plivoBrowserSdk) {
      dispatch(updatePage('login'));
    } else if (
      plivoBrowserSdk.client.isLoggedIn &&
      !plivoBrowserSdk.client.callSession
    ) {
      dispatch(updatePage('home'));
    } else if (state.username && state.password) {
      // await plivoBrowserSdk.client.login(state.username, state.password);
      dispatch(
        handleLogin({
          name: state.name,
          email: state.email,
          username: state.username,
          password: state.password,
          phoneNumbers: state.phoneNumbers,
          token: state.token,
        })
      );
    }
  };

  useEffect(() => {
    // Network Events
    if (!navigator.onLine) {
      dispatch(sendMessage({ type: 'error', type: 'Internet not available.' }));
      dispatch(updatePage('nointernet'));
    }

    window.addEventListener('offline', (e) => {
      dispatch(sendMessage({ type: 'error', type: 'Internet not available.' }));
      dispatch(updatePage('nointernet'));
    });

    window.addEventListener('online', (e) => {
      dispatch(sendMessage({ type: 'success', type: 'Internet available.' }));
      checkPlivoLogin();
    });

    loadPlivo();
    const ustUser = localStorage.getItem('ustelephony24')
      ? JSON.parse(localStorage.getItem('ustelephony24'))
      : null;
    if (ustUser) {
      dispatch(handleLogin(ustUser));
    }
  }, []);
  const renderPage = (pageState) => {
    switch (pageState) {
      case 'login':
        return <Login />;

      case 'home':
        return <Home />;

      case 'incoming':
        return <IncomingCall />;

      case 'call':
        return <OngoingCall />;

      case 'nointernet':
        return (
          <>
            <Header />
            <Stack
              style={{ background: colors.backgroundGradient, height: '90vh' }}
              justifyContent='center'
              alignItems='center'
            >
              <h2>Internet Not Available</h2>
            </Stack>
          </>
        );

      default:
        return '';
    }
  };
  return renderPage(state.pageState);
};

export default App;
