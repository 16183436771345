import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Container,
  Content,
  FlexboxGrid,
  Form,
  Header,
  Loader,
  Panel,
  Schema,
} from 'rsuite';
import { colors } from '../utils';
import ForgotModal from './ForgotModal';
import LoginHeader from './LoginHeader';
import { login } from './loginSlice';

const model = Schema.Model({
  email: Schema.Types.StringType().isEmail(
    'Please enter a valid email address.'
  ),
  password: Schema.Types.StringType().isRequired('This field is required.'),
});

const index = () => {
  const [forgotModal, setForgotModal] = useState(false);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    email: JSON.parse(localStorage.getItem('ustelephony24'))?.email || '',
    password: JSON.parse(localStorage.getItem('ustelephony24'))?.password || '',
  });

  const state = useSelector((state) => state.login);

  const dispatch = useDispatch();

  const toggleForgotModal = () => setForgotModal(!forgotModal);

  const handleSubmit = () => {
    dispatch(login(formValue));
  };

  return (
    <div
      className='show-fake-browser login-page'
      style={{ ...colors.backgroundGradient }}
    >
      <Container>
        <Header>
          <LoginHeader />
        </Header>
        <Content>
          <FlexboxGrid
            justify='center'
            align='middle'
            style={{ height: '92vh' }}
          >
            <FlexboxGrid.Item colspan={12}>
              <Panel
                header={<h3 style={{ color: colors.background }}>Login</h3>}
                style={{ background: colors.primary, color: colors.background }}
                bordered
              >
                {state.loginLoading ? (
                  <Loader backdrop content='loading...' vertical />
                ) : (
                  <Form
                    fluid
                    model={model}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                  >
                    <Form.Group>
                      <Form.ControlLabel>Email</Form.ControlLabel>
                      <Form.Control name='email' />
                    </Form.Group>
                    <Form.Group>
                      <Form.ControlLabel>Password</Form.ControlLabel>
                      <Form.Control
                        name='password'
                        type='password'
                        autoComplete='off'
                      />
                    </Form.Group>
                    <Form.Group>
                      <ButtonToolbar>
                        <Button
                          onClick={handleSubmit}
                          disabled={state.loginLoading}
                          appearance='primary'
                          type='submit'
                        >
                          Sign in
                        </Button>
                        <Button
                          appearance='link'
                          disabled={state.loginLoading}
                          onClick={toggleForgotModal}
                        >
                          Forgot password?
                        </Button>
                      </ButtonToolbar>
                    </Form.Group>
                  </Form>
                )}
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Content>
      </Container>
      <ForgotModal toggleModal={toggleForgotModal} modelState={forgotModal} />
    </div>
  );
};

export default index;
