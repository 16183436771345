import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  SelectPicker,
  Stack,
  Input,
  Checkbox,
  Loader,
} from 'rsuite';
import { countryCodes } from '../utils';
import { getSipUserData, savePhoneData, updateUserState } from './userSlice';

const BackupPhoneModal = ({ toggleModal, modelState }) => {
  const state = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <Modal
      open={modelState}
      onClose={toggleModal}
      size='xs'
      onEnter={() => dispatch(getSipUserData('backupPhoneLoading'))}
    >
      <Modal.Header>
        <Modal.Title>Update Backup Phone Number</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {state.backupPhoneLoading ? (
          <Loader backdrop content='loading...' vertical />
        ) : (
          <Stack
            spacing={24}
            direction='column'
            alignItems='center'
            justifyContent='space-between'
          >
            <SelectPicker
              data={countryCodes}
              size='md'
              style={{ width: '15rem' }}
              onChange={(value) =>
                dispatch(
                  updateUserState({ key: 'backupPhoneCountryCode', value })
                )
              }
              value={state.backupPhoneCountryCode}
            />
            <Input
              style={{ width: '15rem' }}
              onChange={(value) =>
                dispatch(updateUserState({ key: 'backupPhone', value }))
              }
              value={state.backupPhone}
              placeholder='Phone Number'
            />
            <Checkbox
              checked={state.backupPhoneStatus}
              value={state.backupPhoneStatus}
              onChange={(value, isChecked) =>
                dispatch(
                  updateUserState({
                    key: 'backupPhoneStatus',
                    value: isChecked,
                  })
                )
              }
            >
              {' '}
              Make Active for Incoming Calls
            </Checkbox>
            <Checkbox
              checked={state.backupOutboundStatus}
              value={state.backupOutboundStatus}
              onChange={(value, isChecked) =>
                dispatch(
                  updateUserState({
                    key: 'backupOutboundStatus',
                    value: isChecked,
                  })
                )
              }
            >
              {' '}
              Make Active for Outgoing Calls
            </Checkbox>
          </Stack>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => dispatch(savePhoneData(toggleModal))}
          appearance='primary'
          disabled={state.backupPhoneLoading}
        >
          Save
        </Button>
        <Button
          onClick={toggleModal}
          appearance='subtle'
          disabled={state.backupPhoneLoading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BackupPhoneModal;
