import { createSlice } from '@reduxjs/toolkit';

import { sendMessage } from '../App/appSlice';

const initialState = {
  speakerId: '',
  micId: '',
  availableSpeakers: [],
  availableMics: [],
};

export const audioDeviceSlice = createSlice({
  name: 'audioDevice',
  initialState,
  reducers: {
    updateDeviceDetails: (state, action) => {
      state.micId = action.payload.micId;
      state.speakerId = action.payload.speakerId;
      state.availableMics = action.payload.availableMics;
      state.availableSpeakers = action.payload.availableSpeakers;
    },
    updateAudioDeviceId: (state, action) => {
      const { type, value } = action.payload;
      state[type] = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateDeviceDetails, updateAudioDeviceId } =
  audioDeviceSlice.actions;

export const fetchDeviceDetails = (action) => async (dispatch, getState) => {
  try {
    const devicesList = await plivoBrowserSdk.client.audio.availableDevices();
    const micId = await plivoBrowserSdk.client.audio.microphoneDevices.get();
    const speakerId = await plivoBrowserSdk.client.audio.speakerDevices.get();
    const availableMics = [];
    const availableSpeakers = [];
    devicesList.map((deviceItem) => {
      if (deviceItem.label && deviceItem.kind === 'audioinput') {
        availableMics.push({
          value: deviceItem.deviceId,
          label: deviceItem.label,
        });
      } else if (deviceItem.label && deviceItem.kind === 'audiooutput') {
        availableSpeakers.push({
          value: deviceItem.deviceId,
          label: deviceItem.label,
        });
      }
    });

    dispatch(
      updateDeviceDetails({
        micId,
        speakerId,
        availableMics,
        availableSpeakers,
      })
    );
  } catch (err) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Error fetching audio devices, please try again.',
      })
    );
    return;
  }
};

export const saveSpeaker = (action) => async (dispatch, getState) => {
  const speakerId = action;
  if (!speakerId) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Please select speaker.',
      })
    );
    return;
  }
  const speakerUpdate = await plivoBrowserSdk.client.audio.speakerDevices.set(
    speakerId
  );
  const ringtoneUpdate = await plivoBrowserSdk.client.audio.ringtoneDevices.set(
    speakerId
  );
  dispatch(updateAudioDeviceId({ type: 'speakerId', value: speakerId }));
  if (speakerUpdate && ringtoneUpdate) {
    dispatch(
      sendMessage({
        type: 'success',
        text: 'Speaker updated successfully.',
      })
    );
    toggleModal();
  } else {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Something went wrong! Please try again.',
      })
    );
    return;
  }
};

export const saveMic = (action) => async (dispatch, getState) => {
  const micId = action;
  if (!micId) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Please select microphone.',
      })
    );
    return;
  }
  const micUpdate = await plivoBrowserSdk.client.audio.microphoneDevices.set(
    micId
  );
  dispatch(updateAudioDeviceId({ type: 'micId', value: micId }));
  if (micUpdate) {
    dispatch(
      sendMessage({
        type: 'success',
        text: 'Microphone updated successfully.',
      })
    );
    toggleModal();
  } else {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Something went wrong! Please try again.',
      })
    );
    return;
  }
};

export default audioDeviceSlice.reducer;
