import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Loader, Modal, Stack } from 'rsuite';
import { reportIssue } from './callSlice';

const CallBugModal = ({ modelState, toggleModal }) => {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const state = useSelector((state) => state.call);
  const dispatch = useDispatch();
  return (
    <Modal open={modelState} onClose={toggleModal} size='sm'>
      <Modal.Header>
        <Modal.Title>Report an issue</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {state.callBugLoading ? (
          <Loader backdrop content='loading...' vertical />
        ) : (
          <Stack
            spacing={24}
            direction='column'
            alignItems='center'
            justifyContent='space-between'
          >
            <Input
              style={{ width: '25rem' }}
              placeholder='Title'
              maxLength='50'
              value={title}
              onChange={(value) => setTitle(value)}
            />
            <Input
              as='textarea'
              rows={4}
              style={{ width: '25rem' }}
              placeholder='Describe the issue'
              value={message}
              onChange={(value) => setMessage(value)}
            />
          </Stack>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => dispatch(reportIssue({ title, message, toggleModal }))}
          appearance='primary'
          disabled={state.callBugLoading}
        >
          Report
        </Button>
        <Button
          onClick={toggleModal}
          appearance='subtle'
          disabled={state.callBugLoading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CallBugModal;
