import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, SelectPicker, Stack, Input, Loader } from 'rsuite';
import { getDomains, sendForgotPasswordMail } from './loginSlice';

const ForgotModal = ({ toggleModal, modelState }) => {
  const state = useSelector((state) => state.login);
  const [email, setEmail] = useState('');
  const [domain, setDomain] = useState('');
  const dispatch = useDispatch();

  return (
    <Modal open={modelState} onClose={toggleModal} size='sm'>
      <Modal.Header>
        <Modal.Title>Forgot Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {state.loginLoading ? (
          <Loader backdrop content='loading...' vertical />
        ) : (
          <Stack
            spacing={24}
            direction='column'
            alignItems='center'
            justifyContent='space-between'
          >
            <Stack
              alignItems='center'
              justifyContent='space-between'
              style={{ width: '25rem' }}
            >
              <Input
                style={{ width: '15rem' }}
                placeholder='Email'
                onChange={(value) => setEmail(value)}
              />
              <Button
                color='blue'
                onClick={() => dispatch(getDomains({ email }))}
                appearance='ghost'
                size='md'
              >
                Get Domains
              </Button>
            </Stack>
            <SelectPicker
              data={state.domains}
              size='md'
              style={{ width: '25rem' }}
              onChange={(value) => setDomain(value)}
              value={domain}
            />
          </Stack>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() =>
            dispatch(sendForgotPasswordMail({ email, domain, toggleModal }))
          }
          disabled={state.loginLoading}
          appearance='primary'
        >
          Send Email
        </Button>
        <Button
          onClick={toggleModal}
          appearance='subtle'
          disabled={state.loginLoading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ForgotModal;
