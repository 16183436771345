import { configureStore } from '@reduxjs/toolkit';
import appSlice from './components/App/appSlice';
import loginSlice from './components/Login/loginSlice';
import homeSlice from './components/Home/homeSlice';
import audioDeviceSlice from './components/Home/audioDeviceSlice';
import incomingSlice from './components/IncomingCall/incomingSlice';
import callSlice from './components/OngoingCall/callSlice';
import userSlice from './components/Home/userSlice';

export const store = configureStore({
  reducer: {
    app: appSlice,
    login: loginSlice,
    home: homeSlice,
    audioDevice: audioDeviceSlice,
    incomingCall: incomingSlice,
    call: callSlice,
    user: userSlice,
  },
});
