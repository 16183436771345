import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Row, Col, List, Loader } from 'rsuite';
import { colors } from '../utils';
import { dropVoicemail, getVoicemailDrops } from './callSlice';

const VoicemailDropModal = ({ modelState, toggleModal }) => {
  const [voicemailId, setVoicemailId] = useState('');
  const [voicemailName, setVoicemailName] = useState('');
  const [dropConfirmModal, setDropConfirmModal] = useState(false);
  const state = useSelector((state) => state.call);
  const dispatch = useDispatch();

  const setVoicemailDropItem = (id, label) => {
    setVoicemailId(id);
    setVoicemailName(label);
    setDropConfirmModal(!dropConfirmModal);
  };

  const toggleDropConfirmModal = () => setDropConfirmModal(!dropConfirmModal);

  return (
    <>
      <Modal
        open={modelState}
        onClose={toggleModal}
        size='sm'
        onEnter={() => dispatch(getVoicemailDrops())}
      >
        <Modal.Header>
          <Modal.Title>Voicemail Drop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.voicemailDropLoading ? (
            <Loader backdrop content='loading...' vertical />
          ) : state.voicemailDrops.length ? (
            <List
              size='sm'
              style={{
                overflowY: 'scroll',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
              hover
            >
              {state.voicemailDrops.map((item, index) => (
                <List.Item
                  style={{
                    width: '30rem',
                  }}
                  key={index}
                  index={index}
                >
                  <Row>
                    <Col sm={18}>
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1rem',
                          color: colors.primary,
                        }}
                      >
                        {item.title}
                      </span>
                      <br />
                      <span style={{ color: colors.secondary }}>
                        {item.description}
                      </span>
                    </Col>
                    <Col sm={6} md={3}>
                      <Button
                        onClick={() =>
                          setVoicemailDropItem(item._id, item.title)
                        }
                        appearance='ghost'
                      >
                        Drop Voicemail
                      </Button>
                    </Col>
                  </Row>
                </List.Item>
              ))}
            </List>
          ) : (
            <p style={{ textAlign: 'center' }}>No Voicemail Drops Found</p>
          )}
        </Modal.Body>
      </Modal>
      <Modal open={dropConfirmModal} onClose={toggleDropConfirmModal} size='xs'>
        <Modal.Header>
          <Modal.Title>Drop Voicemail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.voicemailDropLoading ? (
            <Loader backdrop content='loading...' vertical />
          ) : (
            <p>
              Are you sure, you want to drop {voicemailName} voicemail? This
              will end the call and leave a voicemail to customer
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              dispatch(
                dropVoicemail({
                  toggleDropConfirmModal,
                  toggleModal,
                  voicemailId,
                })
              )
            }
            appearance='primary'
            disabled={state.voicemailDropLoading}
          >
            Drop
          </Button>
          <Button
            onClick={toggleDropConfirmModal}
            appearance='subtle'
            disabled={state.voicemailDropLoading}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VoicemailDropModal;
