import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  SelectPicker,
  Input,
  Stack,
  Uploader,
  Checkbox,
  Loader,
} from 'rsuite';
import Recorder from '../Recorder/Recorder';
import {
  getSipUserData,
  saveVoicemailData,
  updateUserState,
} from './userSlice';

const pickerItems = [
  { label: 'Text', value: 'text' },
  { label: 'File', value: 'file' },
  { label: 'Record Online', value: 'online' },
];

const VoicemailModal = ({ toggleModal, modelState }) => {
  const [file, setFile] = useState(null);
  const [blob, setBlob] = useState(null);
  const state = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const typeRenderer = (voicemailType) => {
    switch (voicemailType) {
      case 'text':
        return (
          <Input
            as='textarea'
            rows={4}
            style={{ width: '25rem' }}
            placeholder='Voicemail Message'
            value={state.voicemailTextRecording}
            onChange={(value) =>
              dispatch(
                updateUserState({ key: 'voicemailTextRecording', value })
              )
            }
          />
        );

      case 'file':
        return (
          <Uploader
            accept='audio/*'
            onChange={(files) => {
              if (files.length) {
                setFile(files[0]);
              }
            }}
            disabled={!!file}
            action=''
            onRemove={() => {
              setFile(null);
            }}
            autoUpload={false}
            disableMultipart
          />
        );

      case 'online':
        return <Recorder setBlob={(blobFile) => setBlob({ blob: blobFile })} />;

      default:
        break;
    }
  };

  return (
    <Modal
      open={modelState}
      onClose={toggleModal}
      onEnter={() => dispatch(getSipUserData('voicemailLoading'))}
    >
      <Modal.Header>
        <Modal.Title>Update Voicemail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {state.voicemailLoading ? (
          <Loader backdrop content='loading...' vertical />
        ) : (
          <Stack
            spacing={24}
            direction='column'
            alignItems='center'
            justifyContent='space-between'
          >
            <Stack
              alignItems='center'
              justifyContent='space-between'
              style={{ width: '25rem' }}
            >
              <SelectPicker
                data={pickerItems}
                size='md'
                style={{ width: '15rem' }}
                onChange={(value) =>
                  dispatch(
                    updateUserState({ key: 'voicemailRecordingType', value })
                  )
                }
                value={state.voicemailRecordingType}
              />
              <Checkbox
                checked={state.voicemailStatus}
                value={state.voicemailStatus}
                onChange={(value, isChecked) =>
                  dispatch(
                    updateUserState({
                      key: 'voicemailStatus',
                      value: isChecked,
                    })
                  )
                }
              >
                {' '}
                Make Active
              </Checkbox>
            </Stack>
            {typeRenderer(state.voicemailRecordingType)}
          </Stack>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() =>
            dispatch(saveVoicemailData({ file, blob, toggleModal }))
          }
          appearance='primary'
          disabled={state.voicemailLoading}
        >
          Save
        </Button>
        <Button
          onClick={toggleModal}
          appearance='subtle'
          disabled={state.voicemailLoading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VoicemailModal;
