import React from 'react';
import { Dropdown, IconButton, Stack } from 'rsuite';
import { User } from 'react-feather';

import UST24Logo from '../../media/logo.png';
import { colors } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../App/appSlice';
import { makeDeviceActive } from './homeSlice';

const Header = ({ toggleChangePasswordModal }) => {
  const dispatch = useDispatch();

  const appState = useSelector((state) => state.app);
  return (
    <Stack
      style={{
        maxHeight: '2.5rem',
        marginBottom: '0.5rem',
        borderBottom: `2px solid ${colors.primary}`,
      }}
      justifyContent='space-between'
      alignItems='center'
    >
      <img
        src={UST24Logo}
        style={{ maxHeight: '9rem', width: '2rem' }}
        alt='USTelephony24'
      />
      <p
        style={{
          fontSize: '1.5rem',
          letterSpacing: '0.1rem',
          color: colors.primary,
        }}
      >
        Welcome!{' '}
        <span style={{ fontWeight: 'bold' }}>
          {appState?.name?.substring(0, 35)}
        </span>
      </p>
      <Dropdown
        noCaret
        placement='bottomEnd'
        renderToggle={(props, ref) => (
          <IconButton {...props} ref={ref} icon={<User />} circle size='xs' />
        )}
      >
        <Dropdown.Item onClick={toggleChangePasswordModal}>
          Change Password
        </Dropdown.Item>
        <Dropdown.Item onClick={() => dispatch(makeDeviceActive())}>
          Make Device Active
        </Dropdown.Item>
        <Dropdown.Item onClick={() => dispatch(logout())}>Logout</Dropdown.Item>
      </Dropdown>
    </Stack>
  );
};

export default Header;
