import React, { useState } from 'react';
import { PhoneCall, PhoneOff, MessageSquare } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, IconButton, Stack, Tooltip, Whisper } from 'rsuite';
import { colors } from '../utils';
import { answerCall, rejectCall, rejectWithMessage } from './incomingSlice';

import MessageModal from './MessageModal';

const index = () => {
  const [messageModal, setMessageModal] = useState(false);
  const state = useSelector((state) => state.incomingCall);
  const dispatch = useDispatch();

  const renderMessageDropdown = (message) => (
    <Dropdown.Item onClick={() => dispatch(rejectWithMessage(message))}>
      {message}
    </Dropdown.Item>
  );

  return (
    <div
      style={{
        ...colors.backgroundGradient,
        height: '100vh',
      }}
    >
      <h6
        style={{
          textAlign: 'center',
          letterSpacing: '0.1rem',
        }}
      >
        {state.direction === 'incoming' ? 'Incoming Call' : 'Outgoing Call'}
      </h6>
      <Stack
        spacing={24}
        direction='column'
        justifyContent='space-around'
        alignItems='center'
        style={{
          height: '95vh',
        }}
        className='call-screen-gradient'
      >
        <div>
          <h1 style={{ color: colors.primary, letterSpacing: '0.2rem' }}>
            {state.callerId || state.phoneNumber}
          </h1>
          <h6
            style={{
              textAlign: 'center',
              color: colors.secondary,
              letterSpacing: '0.1rem',
            }}
          >
            Company Number: {state.companyNumber}
          </h6>
        </div>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ width: '20rem' }}
        >
          <Whisper
            placement='bottom'
            controlId='control-id-hover'
            trigger='hover'
            speaker={<Tooltip>Answer</Tooltip>}
          >
            <IconButton
              style={{
                color: 'white',
                backgroundColor: 'green',
              }}
              className='wobble'
              icon={<PhoneCall size={40} />}
              circle
              onClick={() => dispatch(answerCall())}
            />
          </Whisper>
          <Whisper
            placement='bottom'
            controlId='control-id-hover'
            trigger='hover'
            speaker={<Tooltip>Reject with Message</Tooltip>}
          >
            <Dropdown
              placement='topStart'
              renderToggle={(props, ref) => (
                <IconButton
                  {...props}
                  ref={ref}
                  style={{
                    color: 'white',
                    backgroundColor: 'blue',
                  }}
                  disabled={state.direction === 'outgoing'}
                  icon={<MessageSquare size={40} />}
                  circle
                />
              )}
            >
              {renderMessageDropdown('We will call you right back.')}
              {renderMessageDropdown(
                "Sorry I'm busy. We will call back later."
              )}
              {renderMessageDropdown(
                "I'm in a meeting. We will call back later."
              )}
              {renderMessageDropdown("I'm driving, We will call back later.")}
              {renderMessageDropdown(
                "Can't talk now. We will call back later."
              )}
              <Dropdown.Item onClick={() => setMessageModal(!messageModal)}>
                Custom
              </Dropdown.Item>
            </Dropdown>
          </Whisper>
          <Whisper
            placement='bottom'
            controlId='control-id-hover'
            trigger='hover'
            speaker={<Tooltip>Reject</Tooltip>}
          >
            <IconButton
              style={{
                color: 'white',
                backgroundColor: 'red',
              }}
              icon={<PhoneOff size={40} />}
              circle
              onClick={() => dispatch(rejectCall())}
            />
          </Whisper>
        </Stack>
      </Stack>
      <MessageModal
        modelState={messageModal}
        toggleModal={() => setMessageModal(!messageModal)}
      />
    </div>
  );
};

export default index;
