import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Stack } from 'rsuite';
import { sendMessage } from '../App/appSlice';

const Recorder = ({ setBlob }) => {
  const [gumStream, setGumStream] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [recorder, setRecorder] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRecorded, setIsRecorded] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const audioEl = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    setAudioContext(new AudioContext());
  }, []);

  const startRecording = async () => {
    setIsRecording(true);
    setIsRecorded(false);
    const constraints = {
      audio: true,
      video: false,
    };

    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      await setGumStream(stream);
      const inputStream = audioContext.createMediaStreamSource(stream);
      const newRecorder = new window.Recorder(inputStream, {
        numChannels: 1,
      });
      setRecorder(newRecorder);
      newRecorder.record();
    } catch (err) {
      dispatch(
        sendMessage({
          type: 'error',
          text: 'Error starting recording, please check audio permissions.',
        })
      );
      // console.log(err);
    }
  };

  const saveAudio = (blob) => {
    setBlob(blob);
    const url = URL.createObjectURL(blob);
    audioEl.current.src = url;
    setIsRecorded(true);
  };

  const stopRecording = () => {
    if (recorder) {
      setIsRecording(false);
      recorder.stop();
      gumStream.getAudioTracks()[0].stop();
      recorder.exportWAV(saveAudio);
    }
  };

  return (
    <div>
      <div>
        <audio className='recorder-audio' ref={audioEl} />
      </div>
      <Stack
        direction='column'
        justifyContent='space-between'
        alignItems='center'
        style={{ height: '5rem' }}
      >
        <Stack
          justifyContent='space-around'
          alignItems='center'
          style={{ width: '25rem' }}
        >
          <Button
            color='blue'
            disabled={isRecording}
            onClick={startRecording}
            appearance='ghost'
            size='md'
          >
            Start Recording
          </Button>
          <Button
            color='blue'
            disabled={!isRecording}
            onClick={stopRecording}
            appearance='ghost'
            size='md'
          >
            Stop Recording
          </Button>
        </Stack>

        {isRecorded && (
          <Button
            color='blue'
            onClick={() => {
              setIsPlaying(isPlaying ? false : true);
              isPlaying ? audioEl.current.pause() : audioEl.current.play();
            }}
            appearance='ghost'
            size='md'
          >
            {isPlaying ? 'Pause Audio' : 'Play Audio'}
          </Button>
        )}
      </Stack>
    </div>
  );
};

export default Recorder;
