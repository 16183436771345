import React from 'react';
import { Row, Col, Button } from 'rsuite';

const DigitRenderer = ({ clickHandler }) => {
  const digitsArr = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['*', '0', '#'],
  ];
  return digitsArr.map((digits, index) => (
    <Row style={{ marginBottom: '0.5rem' }} key={index}>
      {digits.map((digitEl) => (
        <Col key={digitEl} sm={8}>
          <Button
            color='blue'
            appearance='ghost'
            style={{ borderRadius: '50%' }}
            size='lg'
            onClick={() => clickHandler(digitEl)}
          >
            {digitEl}
          </Button>
        </Col>
      ))}
    </Row>
  ));
};

export default DigitRenderer;
