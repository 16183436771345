import axios from 'axios';

export const initAxios = (loginToken) => {
  const token =
    loginToken ||
    localStorage
      .getItem('ustelephony24')
      ?.JSON.parse(localStorage.getItem('ustelephony24'))?.token;
  // axios.defaults.baseURL = baseUrl;
  if (token) {
    axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }
};
