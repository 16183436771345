import React, { useState } from 'react';
import { Button, Modal, Row, Col, List, Loader } from 'rsuite';

import { colors } from '../utils';
import { useSelector, useDispatch } from 'react-redux';
import { getParticipants, removeParticipant } from '../OngoingCall/callSlice';

const ParticipantModal = ({ modelState, toggleModal }) => {
  const [memberId, setMemberId] = useState('');
  const [memberName, setMemberName] = useState('');
  const [participantRemovalModal, setParticipantRemovalModal] = useState(false);
  const state = useSelector((state) => state.call);
  const dispatch = useDispatch();

  const setSelectedParticipant = (selectedMemberId, selectedMemberName) => {
    setMemberId(selectedMemberId);
    setMemberName(selectedMemberName);
    setParticipantRemovalModal(!participantRemovalModal);
  };

  const toggleParticipantRemovalModal = () =>
    setParticipantRemovalModal(!participantRemovalModal);

  return (
    <>
      <Modal
        open={modelState}
        onClose={toggleModal}
        size='xs'
        onEnter={() => dispatch(getParticipants())}
      >
        <Modal.Header>
          <Modal.Title>Call Participants</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.participantsLoading ? (
            <Loader backdrop content='loading...' vertical />
          ) : state.participants.length ? (
            <List
              size='sm'
              style={{
                overflowY: 'scroll',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
              hover
            >
              {state.participants.map((item, index) => (
                <List.Item
                  style={{
                    width: '20rem',
                  }}
                  key={index}
                  index={index}
                >
                  <Row>
                    <Col sm={18}>
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1rem',
                          color: colors.primary,
                        }}
                      >
                        {item.label}
                      </span>
                      <br />
                      <span style={{ color: colors.secondary }}>
                        {item.type}
                      </span>
                    </Col>
                    <Col sm={6}>
                      <Button
                        onClick={() =>
                          setSelectedParticipant(item.value, item.label)
                        }
                        color='red'
                        appearance='ghost'
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                </List.Item>
              ))}
            </List>
          ) : (
            <p style={{ textAlign: 'center' }}>No Participants Found</p>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        open={participantRemovalModal}
        onClose={toggleParticipantRemovalModal}
        size='xs'
      >
        <Modal.Header>
          <Modal.Title>Remove Participant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.participantsLoading ? (
            <Loader backdrop content='loading...' vertical />
          ) : (
            <h6>Are you sure, you want to remove {memberName} from the call</h6>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(
                removeParticipant({
                  toggleModal,
                  toggleParticipantRemovalModal,
                  memberId,
                })
              );
              toggleParticipantRemovalModal();
            }}
            color='red'
            appearance='primary'
            disabled={state.participantsLoading}
          >
            Yes, Remove
          </Button>
          <Button
            onClick={toggleParticipantRemovalModal}
            appearance='subtle'
            disabled={state.participantsLoading}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ParticipantModal;
