import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import { SERVER_URL } from '../utils';
import { logout, sendMessage } from '../App/appSlice';

const initialState = {
  callLogs: [],
  selectedIndex: -1,
  selectedLog: {},
  progressives: [],
  savedCallLogs: [],
  bitrixName: 'Loading...',
  filterDirection: 'all',
  callLogLoading: false,
  progressiveLoading: false,
  changePasswordLoading: false,
  logoutLoading: false,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    updateHomeState: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    updateLogs: (state, action) => {
      state.callLogs = action.payload;
      state.savedCallLogs = action.payload;
      state.filterDirection = 'all';
    },
    updateSelectedLog: (state, action) => {
      state.selectedIndex = action.payload;
      state.selectedLog = state.callLogs[action.payload];
      state.bitrixName = '';
    },
    updateProgressiveList: (state, action) => {
      state.progressives = action.payload;
    },
    updateBitrixName: (state, action) => {
      state.bitrixName = action.payload;
    },
    filterCallLogs: (state, action) => {
      if (action.payload === 'all') {
        state.callLogs = state.savedCallLogs;
      } else {
        state.callLogs = state.savedCallLogs.filter(
          (callLog) => callLog.direction === action.payload
        );
      }
      state.filterDirection = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateLogs,
  updateSelectedLog,
  updateProgressiveList,
  updateBitrixName,
  updateHomeState,
  filterCallLogs,
} = homeSlice.actions;

export const changePassword = (action) => async (dispatch, getState) => {
  try {
    const { password, newPassword, confirmPassword, toggleModal } = action;
    const passwordRegEx =
      /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9!@#$%^&*()+=-?;,./{}|":<>[\]\\' ~_]+)$/;
    if (newPassword !== confirmPassword) {
      dispatch(
        sendMessage({
          type: 'error',
          text: `Password doesn't match`,
        })
      );
      return;
    } else if (
      !passwordRegEx.test(newPassword) ||
      newPassword.length < 7 ||
      newPassword.length > 15
    ) {
      dispatch(
        sendMessage({
          type: 'error',
          text: `Password should be between 7 to 15 characters long and must contain atleast 1 digit and 1 alphabet.`,
        })
      );
      return;
    }

    const appState = getState().app;
    dispatch(updateHomeState({ key: 'changePasswordLoading', value: true }));

    await axios.post(`${SERVER_URL}/auth/changeDialerPassword`, {
      password,
      newPassword,
      confirmPassword,
      email: appState.email,
    });
    toggleModal();
    dispatch(logout());
    dispatch(
      sendMessage({
        type: 'success',
        text: 'Password Updated Successfully. Please login again.',
      })
    );
    dispatch(updateHomeState({ key: 'changePasswordLoading', value: false }));
  } catch (err) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Error updating new password! Please try again.',
      })
    );
    dispatch(updateHomeState({ key: 'changePasswordLoading', value: false }));
    return;
  }
};

export const makeDeviceActive = (action) => async (dispatch, getState) => {
  try {
    await axios.post(`${SERVER_URL}/user/loginUser`, {
      type: 'desktop',
    });
    dispatch(
      sendMessage({
        type: 'success',
        text: 'Device activated succesfully',
      })
    );
  } catch (error) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Error activating device',
      })
    );
  }
};

export const getCallLogs = (action) => async (dispatch, getState) => {
  try {
    dispatch(updateHomeState({ key: 'callLogLoading', value: true }));
    const appState = getState().app;
    const employeeReportsResponse = await axios.post(
      `${SERVER_URL}/reports/getEmployeeCallReports`
    );
    const employeeReports = employeeReportsResponse?.data?.calls || [];
    dispatch(updateLogs(employeeReports));
    dispatch(updateHomeState({ key: 'callLogLoading', value: false }));
    if (employeeReports.length) {
      dispatch(fetchSelectedLog(0));
    }
  } catch (err) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Error fetching logs, please try again.',
      })
    );
    return;
  }
};

export const getProgressives = (action) => async (dispatch, getState) => {
  try {
    dispatch(updateHomeState({ key: 'progressiveLoading', value: true }));
    const appState = getState().app;
    const progressiveListResponse = await axios.post(
      `${SERVER_URL}/dialers/progressive/getList`
    );
    dispatch(
      updateProgressiveList(progressiveListResponse.data.progressiveList)
    );
    dispatch(updateHomeState({ key: 'progressiveLoading', value: false }));
  } catch (err) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Error fetching progressive list, please try again.',
      })
    );
    return;
  }
};

export const joinProgressive = (action) => async (dispatch, getState) => {
  try {
    dispatch(updateHomeState({ key: 'progressiveLoading', value: true }));
    const appState = getState().app;
    const progressiveListResponse = await axios.post(
      `${SERVER_URL}/dialers/progressive/joinProgressive`,
      {
        progressiveId: action,
      }
    );
    dispatch(updateHomeState({ key: 'progressiveLoading', value: false }));
  } catch (err) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Error joining progressive, please try again.',
      })
    );
    return;
  }
};

export const getBitrixName = (action) => async (dispatch, getState) => {
  try {
    const appState = getState().app;
    dispatch(updateBitrixName('Loading...'));
    const getBitrixNameResponse = await axios.post(
      `${SERVER_URL}/user/getBitrixName`,
      {
        phoneNumber: action,
      }
    );
    dispatch(updateBitrixName(getBitrixNameResponse.data.bitrixName));
  } catch (err) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Error fetching bitrix name, please try again.',
      })
    );
    return;
  }
};

export const fetchSelectedLog = (action) => async (dispatch, getState) => {
  try {
    const state = getState().home;
    const index = action;
    dispatch(updateSelectedLog(index));
    dispatch(getBitrixName(state?.callLogs[action]?.destination_number));
  } catch (err) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Error fetching selected log, please try again.',
      })
    );
    return;
  }
};

export default homeSlice.reducer;
