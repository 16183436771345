import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, Row, List, Loader } from 'rsuite';
import { colors } from '../utils';
import { getProgressives, joinProgressive } from './homeSlice';

const ProgressiveListModal = ({ modelState, toggleModal }) => {
  const state = useSelector((state) => state.home);
  const dispatch = useDispatch();
  return (
    <Modal
      open={modelState}
      onClose={toggleModal}
      size='xs'
      onEnter={() => dispatch(getProgressives())}
    >
      <Modal.Header>
        <Modal.Title>Ongoing Progressive Lists</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {state.progressiveLoading ? (
          <Loader backdrop content='loading...' vertical />
        ) : state.progressives.length ? (
          <List
            size='sm'
            style={{
              overflowY: 'scroll',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
            hover
          >
            {state.progressives.map((item, index) => (
              <List.Item
                style={{
                  width: '20rem',
                }}
                key={index}
                index={index}
              >
                <Row>
                  <Col sm={18}>
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        color: colors.primary,
                      }}
                    >
                      {item.label}
                    </span>
                  </Col>
                  <Col sm={6}>
                    <Button
                      onClick={() => dispatch(joinProgressive(item.value))}
                      color='green'
                      appearance='ghost'
                    >
                      Join
                    </Button>
                  </Col>
                </Row>
              </List.Item>
            ))}
          </List>
        ) : (
          <p style={{ textAlign: 'center' }}>No Progressive Found</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ProgressiveListModal;
