import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import { SERVER_URL } from '../utils';
import { sendMessage, updateUserDetails } from '../App/appSlice';
import { initAxios } from '../helpers';

const initialState = {
  domains: [],
  loginLoading: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateLoginState: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    updateDomains: (state, action) => {
      const newDomains = action.payload.map((domain) => ({
        label: domain,
        value: domain,
      }));
      state.domains = newDomains;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateDomains, updateLoginState } = loginSlice.actions;

export const checkAudioDevice = (action) => async (dispatch, getState) => {
  await plivoBrowserSdk.client.audio.availableDevices();
  const isMicAvailable =
    await plivoBrowserSdk.client.audio.microphoneDevices.get();
  const isSpeakerAvailable =
    await plivoBrowserSdk.client.audio.speakerDevices.get();
  if (!isMicAvailable) {
    await plivoBrowserSdk.client.audio.microphoneDevices.set('default');
  }
  if (!isSpeakerAvailable) {
    await plivoBrowserSdk.client.audio.speakerDevices.set('default');
    await plivoBrowserSdk.client.audio.ringtoneDevices.set('default');
  }
};

export const login = (action) => async (dispatch, getState) => {
  try {
    const { email, password: userPassword } = action;
    dispatch(updateLoginState({ key: 'loginLoading', value: true }));
    const userLoginResponse = await axios.post(
      `${SERVER_URL}/auth/dialerLogin`,
      { email, password: userPassword, type: 'desktop' }
    );

    const { username, password, token } = userLoginResponse?.data;
    if (!plivoBrowserSdk) {
      dispatch(
        sendMessage({
          type: 'error',
          text: 'App not ready! Please try reloading the app.',
        })
      );
      dispatch(updateLoginState({ key: 'loginLoading', value: false }));
      return;
    }
    initAxios(token);
    const loginResponse = await plivoBrowserSdk.client.login(
      username,
      password
    );
    if (!loginResponse) {
      dispatch(
        sendMessage({
          type: 'error',
          text: 'Something went wrong, please try again',
        })
      );
      dispatch(updateLoginState({ key: 'loginLoading', value: false }));
      return;
    }

    localStorage.setItem(
      'ustelephony24',
      JSON.stringify(userLoginResponse?.data)
    );
    dispatch(updateUserDetails(userLoginResponse?.data));
  } catch (err) {
    dispatch(
      sendMessage({
        type: 'error',
        text:
          err?.response?.data?.message ||
          'Something went wrong! Please try again.',
      })
    );
    dispatch(updateLoginState({ key: 'loginLoading', value: false }));
  }
};

export const getDomains = (action) => async (dispatch, getState) => {
  try {
    const { email } = action;
    dispatch(updateLoginState({ key: 'loginLoading', value: true }));
    const bitrixDomainsResponse = await axios.post(
      `${SERVER_URL}/user/getBitrixDomains`,
      {
        email,
      }
    );
    dispatch(updateDomains(bitrixDomainsResponse.data.domains));
    dispatch(updateLoginState({ key: 'loginLoading', value: false }));
  } catch (error) {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Error fetching domains, please try again.',
      })
    );
    dispatch(updateLoginState({ key: 'loginLoading', value: false }));
  }
};

export const sendForgotPasswordMail =
  (action) => async (dispatch, getState) => {
    try {
      const { email, domain, toggleModal } = action;
      if (!email) {
        dispatch(sendMessage({ type: 'error', text: 'Please fill email.' }));
        return;
      } else if (!domain) {
        dispatch(sendMessage({ type: 'error', text: 'Please select domain.' }));
        return;
      }
      dispatch(updateLoginState({ key: 'loginLoading', value: true }));
      await axios.post(`${SERVER_URL}/auth/forgotDialerPassword`, {
        email,
        selectedDomain: domain,
      });
      dispatch(updateLoginState({ key: 'loginLoading', value: false }));
      dispatch(
        sendMessage({
          type: 'success',
          text: 'Password reset mail sent! Please check your inbox.',
        })
      );
      toggleModal();
    } catch (error) {
      dispatch(
        sendMessage({
          type: 'error',
          text: 'Somthing went wrong! Please try again.',
        })
      );
      dispatch(updateLoginState({ key: 'loginLoading', value: false }));
      return;
    }
  };

export default loginSlice.reducer;
