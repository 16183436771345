import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { updatePage, sendMessage } from '../App/appSlice';
import { filterNumber, SERVER_URL } from '../utils';

const initialState = {
  callerId: '',
  phoneNumber: '',
  companyNumber: '',
  callUUID: '',
  direction: '',
  mute: false,
  hold: false,
  clientHold: false,
  users: [],
  participants: [],
  time: 0,
  timeInterval: null,
  isSupervisor: false,
  voicemailDrops: [],
  callBugLoading: false,
  participantsLoading: false,
  addParticipantLoading: false,
  voicemailDropLoading: false,
  callLoading: false,
  callState: 'Initiated',
  isProgressive: false,
  progressiveId: '',
  mpcName: '',
};

export const callSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    updateCallState: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    updateCallInfo: (state, action) => {
      state.callerId = action.payload.callerId;
      state.phoneNumber = action.payload.phoneNumber;
      state.companyNumber = action.payload.companyNumber;
      state.callUUID = action.payload.callUUID;
      state.direction = action.payload.direction;
      state.isProgressive = action.payload.isProgressive;
      state.progressiveId = action.payload.progressiveId;
      state.mpcName = action.payload.mpcName;
    },
    clearCallInfo: (state, action) => {
      state.callerId = '';
      state.phoneNumber = '';
      state.companyNumber = '';
      state.callUUID = '';
      state.direction = '';
      state.mute = false;
      state.hold = false;
      state.clientHold = false;
      state.users = [];
      state.participants = [];
      state.time = 0;
      state.isSupervisor = false;
      state.voicemailDrops = [];
      state.callBugLoading = false;
      state.participantsLoading = false;
      state.addParticipantLoading = false;
      state.voicemailDropLoading = false;
      state.callLoading = false;
      state.callState = 'Initiated';
      state.isProgressive = false;
      state.progressiveId = '';
      state.mpcName = '';
    },
    updateTime: (state, action) => {
      state.time = state.time + 1;
    },
    updateSupervisorState: (state, action) => {
      state.isSupervisor = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateCallInfo,
  clearCallInfo,
  updateCallState,
  updateTime,
  updateSupervisorState,
} = callSlice.actions;

export const handleApiRequest = (action) => async (dispatch, getState) => {
  const state = getState().call;
  const {
    url,
    data,
    defaultErrorMessage,
    returnData,
    successMessage,
    defaultReturn,
  } = action;

  if (!state.callUUID) {
    dispatch(sendMessage({ type: 'error', text: 'Call not active.' }));
    dispatch(updateCallState({ key: 'callLoading', value: false }));
    return defaultReturn;
  }
  try {
    dispatch(updateCallState({ key: 'callLoading', value: true }));
    const response = await axios.post(`${SERVER_URL}/${url}`, data);
    dispatch(updateCallState({ key: 'callLoading', value: false }));
    if (successMessage) {
      dispatch(sendMessage({ type: 'success', text: successMessage }));
    }
    if (returnData) {
      return response?.data[returnData];
    }
  } catch (error) {
    dispatch(
      sendMessage({
        type: 'error',
        text: error?.response?.data?.message || defaultErrorMessage,
      })
    );
    dispatch(updateCallState({ key: 'callLoading', value: false }));
    return defaultReturn;
  }
};

export const toggleMute = (action) => async (dispatch, getState) => {
  const state = await getState().call;
  const muteData = await dispatch(
    handleApiRequest({
      url: 'call/mute',
      data: {
        muteState: state.mute,
        callUUID: state.callUUID,
      },
      successMessage: `${state.mute ? 'UnMute' : 'Mute'} Successful.`,
      defaultErrorMessage: 'Error muting participant! Please try again.',
      returnData: 'callMute',
      defaultReturn: state.mute,
    })
  );
  dispatch(updateCallState({ key: 'mute', value: muteData }));
};

export const toggleHold = (action) => async (dispatch, getState) => {
  const state = getState().call;
  const holdData = await dispatch(
    handleApiRequest({
      url: 'call/hold',
      data: {
        holdState: state.hold,
        callUUID: state.callUUID,
      },
      successMessage: `${state.hold ? 'UnHold' : 'Hold'} Successful.`,
      defaultErrorMessage: 'Error holding participant! Please try again.',
      returnData: 'callHold',
      defaultReturn: state.hold,
    })
  );
  dispatch(updateCallState({ key: 'hold', value: holdData }));
};

export const sendDigits = (action) => async (dispatch, getState) => {
  if (plivoBrowserSdk && plivoBrowserSdk.client.callSession && action) {
    plivoBrowserSdk.client.sendDtmf(action);
  }
};

export const getUsers = (action) => async (dispatch, getState) => {
  const appState = getState().app;
  const state = getState().call;
  dispatch(updateCallState({ key: 'addParticipantLoading', value: true }));
  const employeesData = await dispatch(
    handleApiRequest({
      url: 'call/getEmployees/',
      data: {
        callUUID: state.callUUID,
      },
      defaultErrorMessage: 'Error Fetching employees, Please try again.',
      returnData: 'sipUsers',
      defaultReturn: state.users,
    })
  );
  dispatch(updateCallState({ key: 'addParticipantLoading', value: false }));
  if (employeesData) {
    dispatch(updateCallState({ key: 'users', value: employeesData }));
  }
};

export const addUser = (action) => async (dispatch, getState) => {
  const appState = getState().app;
  const state = getState().call;
  const { sipEndpoint, toggleModal } = action;
  dispatch(updateCallState({ key: 'addParticipantLoading', value: true }));
  await dispatch(
    handleApiRequest({
      url: 'call/transferCall',
      data: {
        sipEndpoint: sipEndpoint,
        callUUID: state.callUUID,
        memberType: 'agent',
        username: appState.username,
      },
      successMessage: `User added Successfully.`,
      defaultErrorMessage: `Error adding agent, Please try again.`,
    })
  );
  dispatch(updateCallState({ key: 'addParticipantLoading', value: false }));
  toggleModal();
};

export const getParticipants = (action) => async (dispatch, getState) => {
  const state = getState().call;
  dispatch(updateCallState({ key: 'participantsLoading', value: true }));
  const callParticipants = await dispatch(
    handleApiRequest({
      url: 'call/getParticipants',
      data: {
        callUUID: state.callUUID,
      },
      defaultErrorMessage:
        'Error fetching call participants, Please try again.',
      returnData: 'participantsList',
      defaultReturn: state.participants,
    })
  );
  dispatch(updateCallState({ key: 'participantsLoading', value: false }));
  dispatch(updateCallState({ key: 'participants', value: callParticipants }));
};

export const removeParticipant = (action) => async (dispatch, getState) => {
  const { memberId, toggleModal, toggleParticipantRemovalModal } = action;
  const state = getState().call;
  dispatch(updateCallState({ key: 'participantsLoading', value: true }));
  await dispatch(
    handleApiRequest({
      url: 'call/kickParticipant',
      data: {
        callUUID: state.callUUID,
        memberId,
      },
      successMessage: `Participant removed Successfully.`,
      defaultErrorMessage: 'Error kicking participants, Please try again.',
    })
  );
  dispatch(updateCallState({ key: 'participantsLoading', value: false }));
  toggleParticipantRemovalModal();
  toggleModal();
};

export const hangupCall = (action) => async (dispatch, getState) => {
  try {
    plivoBrowserSdk.client.hangup();
  } catch (err) {
    return null;
  }
};

export const toggleClientHold = (action) => async (dispatch, getState) => {
  const state = getState().call;
  const holdClientData = await dispatch(
    handleApiRequest({
      url: 'call/holdClient/',
      data: {
        holdState: state.clientHold,
        callUUID: state.callUUID,
      },
      successMessage: `${
        state.clientHold ? 'Unhold Caller' : 'Hold Caller'
      } Successful.`,
      defaultErrorMessage: 'Error holding Caller, Please try again.',
      returnData: 'clientCallHold',
      defaultReturn: state.clientHold,
    })
  );
  dispatch(updateCallState({ key: 'clientHold', value: holdClientData }));
};

export const optOutCustomer = (action) => async (dispatch, getState) => {
  const appState = getState().app;
  const state = getState().call;
  await dispatch(
    handleApiRequest({
      url: 'call/optOutCustomer',
      data: {
        callUUID: state.callUUID,
      },
      successMessage: 'Caller opted out successfully.',
      defaultErrorMessage: 'Error Opting Out Caller, Please try again.',
    })
  );
};

export const blockCustomer = (action) => async (dispatch, getState) => {
  const appState = getState().app;
  const state = getState().call;
  await dispatch(
    handleApiRequest({
      url: 'call/blockCustomer',
      data: {
        callUUID: state.callUUID,
      },
      successMessage: 'Caller blocked successfully.',
      defaultErrorMessage: 'Error Blocking Caller, Please try again.',
    })
  );
};

export const takeoverCall = (action) => async (dispatch, getState) => {
  const state = getState().call;
  await dispatch(
    handleApiRequest({
      url: 'call/takeover/',
      data: {
        callUUID: state.callUUID,
      },
      successMessage: 'Call takeover successful.',
      defaultErrorMessage: 'Error taking over call, Please try again.',
    })
  );
};

export const makeCall = (action) => async (dispatch, getState) => {
  const { number, outgoingNumber } = action;
  const callingNumber = filterNumber(number);
  if (callingNumber) {
    dispatch(
      updateCallInfo({
        callerId: '',
        phoneNumber: callingNumber,
        companyNumber: outgoingNumber,
        callUUID: '',
        direction: 'outgoing',
      })
    );
    dispatch(updatePage('call'));
    await plivoBrowserSdk.client.call(callingNumber, {
      'X-Ph-outgoingCall': true,
      'X-Ph-Companynumber': outgoingNumber,
    });
  } else {
    dispatch(
      sendMessage({
        type: 'error',
        text: 'Number is not valid! Please check the number.',
      })
    );
    return;
  }
};

export const getVoicemailDrops = (action) => async (dispatch, getState) => {
  const appState = getState().app;
  const state = getState().call;
  dispatch(updateCallState({ key: 'voicemailDropLoading', value: true }));
  const voicemailDrops = await dispatch(
    handleApiRequest({
      url: 'call/getVoicemailDrops',
      data: {},
      defaultErrorMessage: 'Error fetching voicemail drops, Please try again.',
      returnData: 'voicemailDrops',
      defaultReturn: state.voicemailDrops,
    })
  );
  dispatch(updateCallState({ key: 'voicemailDropLoading', value: false }));
  dispatch(updateCallState({ key: 'voicemailDrops', value: voicemailDrops }));
};

export const dropVoicemail = (action) => async (dispatch, getState) => {
  const state = getState().call;
  const appState = getState().app;
  const { toggleDropConfirmModal, toggleModal, voicemailId } = action;
  dispatch(updateCallState({ key: 'voicemailDropLoading', value: true }));
  await dispatch(
    handleApiRequest({
      url: 'call/dropVoicemail',
      data: {
        callUUID: state.callUUID,
        voicemailDropId: voicemailId,
      },
      successMessage: 'Voicemail Dropped Successfully.',
      defaultErrorMessage:
        'Error Droping Voicemail, Please make sure call is being answered.',
    })
  );
  dispatch(updateCallState({ key: 'voicemailDropLoading', value: false }));
  toggleDropConfirmModal();
  toggleModal();
  dispatch(hangupCall());
};

export const callNextNumber = (action) => async (dispatch, getState) => {
  const state = getState().call;
  const appState = getState().app;
  await dispatch(
    handleApiRequest({
      url: 'dialers/progressive/callNextNumber',
      data: {
        username: appState.username,
        progressiveId: state.progressiveId,
        mpcName: state.mpcName,
      },
      successMessage: 'Next call initiated successful.',
      defaultMessage: 'Error calling next caller. Please try again!',
    })
  );
};

export const startTimer = (action) => async (dispatch, getState) => {
  clearInterval(window.timeInterval);
  window.timeInterval = null;
  dispatch(
    updateCallState({
      key: 'time',
      value: 0,
    })
  );

  window.timeInterval = setInterval(() => {
    dispatch(updateTime());
  }, 1000);
  // dispatch(
  //   updateCallState({
  //     key: 'timeInterval',
  //     value: setInterval(() => {
  //       dispatch(updateTime());
  //     }, 1000),
  //   })
  // );
};

export const stopTimer = (action) => async (dispatch, getState) => {
  clearInterval(window.timeInterval);
  window.timeInterval = null;
  dispatch(
    updateCallState({
      key: 'time',
      value: 0,
    })
  );
  // if (state.timeInterval) {
  //   clearInterval(state.timeInterval);
  //   dispatch(
  //     updateCallState({
  //       key: 'timeInterval',
  //       value: null,
  //     })
  //   );
  // }
};

export const reportIssue = (action) => async (dispatch, getState) => {
  const state = getState().call;
  const appState = getState().app;
  const { title, message, toggleModal } = action;

  if (!title || !message) {
    dispatch(
      sendMessage({ type: 'error', text: 'Please fill all the details.' })
    );
    return;
  }
  dispatch(updateCallState({ key: 'callBugLoading', value: true }));
  await dispatch(
    handleApiRequest({
      url: 'user/reportIssue',
      data: {
        callUUID: state.callUUID,
        title,
        message,
      },
      successMessage: 'Issue Reported Successfully.',
      defaultErrorMessage: 'Error reporting issue, Please try again.',
    })
  );
  dispatch(updateCallState({ key: 'callBugLoading', value: false }));
  toggleModal();
};

export default callSlice.reducer;
