import React from 'react';
import { Col, Row, Dropdown, IconButton, Stack } from 'rsuite';
import { User } from 'react-feather';

import UST24Logo from '../../media/logo_full.png';
import { colors } from '../utils';

const Header = () => {
  return (
    <Stack
      style={{
        paddingTop: '0.1rem',
        borderBottom: `2px solid ${colors.primary}`,
        width: '100%',
      }}
    >
      <img
        src={UST24Logo}
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '20%',
        }}
        alt='USTelephony24'
      />
    </Stack>
  );
};

export default Header;
